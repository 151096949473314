@font-face {
    font-family: 'Heebo Regular';
    src: url(../fonts/Heebo-Regular.ttf);
  }
body{
    font-family: 'Heebo Regular';
}
.customer-sidebar{
    width: 200px;
    /* height: calc(100vh - 66px); */
    height: 100vh;
    padding-top: 66px;
    transform: translateX(-200px);
    transition: transform 0.3s ease-in-out;
}

.customer-sidebar .active{
    background-color: rgba(20, 40, 98, 0.3);
    /* background-color: #0DCAF0; */
    /* border-right: 4px solid rgb(20, 40, 98);
    border-left: 4px solid rgb(20, 40, 98); */
    /* color: #fff; */
}

.customer-sidebar a {
    text-decoration: none;
}

.customer-sidebar .active a {
    /* color: rgba(0,0,0,0.9); */
    /* color: white; */
}

.main-content{
    width: 100%;
    padding-top: 66px;
}

ul{
    list-style-type: none;
}

.nav-button{
    animation-name: floating;
    animation-duration: 1.9s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
    /* left: -15px; */
    top: -8px;
    /* top: 108px; */
    top: 84px;
    box-shadow: 2px 2px 3px #999;
    /* padding: 0.375rem; */
    /* width: 30px;
    height: 30px; */
    /* border-radius: 50%;
    font-size: 2rem; */
}

.navbar.customer{
    box-shadow: 0px 5px 8px rgba(0, 0, 0, 0.1);
    z-index: 1058;
}

.sell.container {
  max-width: 960px;
}

.sell-backround-img{
    /* width: 1200px; */
    height: 230px;
    background-size: cover;
    background-position: center;
    width: 100%;
}

@keyframes floating {
    0% { 
        /* transform: scale(0.98);  */
        left: -15px; }
    50%   { 
        /* transform: scale(1);  */
        left: -12px; }     
    100%   { 
        /* transform: scale(1);  */
        left: -15px; }    
}

@media(max-width: 767.9px){
    .customer-sidebar.sidebar-out{
        width: 200px;
        transform: translateX(0);
        transition: transform 0.3s ease-in-out;
        /* transition: width 0.3s ease-in-out; */
    }
}

@media(min-width: 768px){
    .customer-sidebar{
        transform: translateX(0);
        transition: transform 0.3s ease-in-out;
        /* transition: width 0.3s ease-in-out; */
    }
    .main-content{
        width: calc(100% - 200px);
        margin-left: 200px;
    }
}

@media(min-width: 992px){
    .customer-sidebar{
        width: 260px;
    }
    .main-content{
        width: calc(100% - 260px);
        margin-left: 260px;
    }
}