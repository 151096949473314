@font-face {
  font-family: 'Heebo Regular';
  src: url(../fonts/Heebo-Regular.ttf);
}


body {
  font-family: 'Heebo Regular';
}

.auth-form {
  background-size: cover;
  background-repeat: no-repeat;
}

.auth-form a,
.auth-form p {
  font-size: 0.85rem;
}

.auth-form button {
  font-size: 0.7rem !important;
}

.btn-group-vertical {
  row-gap: 0.5rem;
}

.btn-group-vertical .btn {
  font-size: 0.85rem !important;
  font-weight: 600;
  text-align: left;
}

.btn-group-vertical .btn:hover {
  background-color: rgba(0, 0, 0, 0.1);
  color: rgba(0, 0, 0, 0.9);
}

.btn-outline-gray {
  border: 1px solid #ccc;
}

.btn-group-vertical>.btn:not(:last-child):not(.dropdown-toggle),
.btn-group-vertical>.btn-group:not(:last-child)>.btn {
  border-bottom-right-radius: 0.375rem;
  border-bottom-left-radius: 0.375rem;
}

/* .btn-group-vertical>.btn:not(:first-child):not(.dropdown-toggle), .btn-group-vertical>.btn-group:not(:first-child)>.btn {
    border-bottom-right-radius: 0.375rem;
    border-bottom-left-radius: 0.375rem;
} */

.btn-group-vertical>.btn~.btn,
.btn-group-vertical>.btn-group:not(:first-child)>.btn {
  border-top-left-radius: 0.375rem;
  border-top-right-radius: 0.375rem;
}

.leftSideContainer {
  width: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  /* padding-left: 40px;
    padding-right: 40px; */
  padding-top: 32px;
}

.formContainer {
  display: flex;
  flex-direction: column;
  /* justify-content: center; */
  /* overflow: auto; */
  align-items: center;
  margin-bottom: 1.3rem;
  flex-grow: 1
}

.mainForm {
  width: 26rem
}

.mainFormRegistration {
  /* margin-top: 1.3rem; */
  /* width: 50rem; */
  /* width: 26rem; */

  /* margin-top: 35rem; */
  width: 26rem;
  padding: 1.5rem;
  background-color: white;
  border-radius: 8px; /* Optional rounded corners */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Optional shadow */
  margin-left: auto;
  margin-right: auto;
  position: absolute;
    margin-top: 0;
    top: 10%;
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
}

.auth-form-scroll{
  height: 100vh; /* Full viewport height */
  display: flex;
  justify-content: center;
  align-items: center;
  overflow-y: auto;
  width: 100%;
  position: relative;
}

.alternate-reg {
  width: 26rem;
  display: flex;
  text-transform: uppercase;
  align-items: center;
  border: none;
  font-size: 12px;
  font-weight: 500;
  margin: 0;
  padding: 24px 0 0;
  column-gap: 0.8rem;
  padding: calc(8px * 3) 0 0 0;
}

.alternate-reg:after,
.alternate-reg:before {
  content: "";
  border-bottom: 1px solid #c2c8d0;
  /* border-bottom: 1px solid var(--border-default-color); */
  flex: 1 0 auto;
  height: fit-content;
  margin: 0;
}

.social-login-btn {
  width: 26rem;
}

.event-list:hover {
  background-color: rgba(0, 0, 0, 0.05);
}

.auth-form-btn {
  width: 500px;
}

/* .auth-wrapper{
  background: url('../');
} */

@media screen and (max-width: 900px) {
  .leftSideContainer {
    width: 100%;
    overflow: visible;
  }

  .authImgContainer {
    width: 50%;
    display: none;
  }
}


@media screen and (max-width: 768px) {
  .mainFormRegistration {
    width: 100%;
  }
}

@media screen and (max-width: 576px) {
  .mainForm {
    width: 100%;
  }

  .alternate-reg,
  .social-login-btn {
    width: 100%;
  }

  .auth-form-btn {
    width: 100%;
  }

  .mainFormRegistration {
    max-width: 100%;
    min-width: 190px;
    overflow: visible;
  }
}
.custom-select {
  font-size: 12px; /* Adjust the font size as needed */
}

.custom-select .select__option {
  font-size: 12px; /* Font size for each option */
}
@media (max-width: 767px) {
  .custom-select{
    font-size: 16px;
  }
}