@font-face {
    font-family: 'Heebo Regular';
    src: url(../fonts/Heebo-Regular.ttf);
  }

body{
    font-family: 'Heebo Regular';
}
.ticket-text {
    font-size: 0.9rem;
}

.ticket-btn {
    background-color: rgba(0, 0, 0, 0.1);
    font-weight: 900;
    /* text-transform: uppercase; */
}

.ticket-card-hide {
    padding: 2rem 0;
    width: 100%;
    background-color: lightgray;
    position: absolute;
    opacity: 0;
    top: 0;
    left: 0;
    transform: translateY(-100px);
    transition: all 0.6s ease-out;
}

.ticket-card {
    opacity: 1;
}

.confirmation-card {
    max-height: 100vh;
    overflow-y: auto;
}

.confirmation-card::-webkit-scrollbar {
    width: 8px;
    height: 8px;

}

.confirmation-card::-webkit-scrollbar-track {
    background: #f1f1f1;
}

/* Handle */
.confirmation-card::-webkit-scrollbar-thumb {
    background: #b0aeae;
    border-radius: 10px;
}

/* Handle on hover */
.confirmation-card::-webkit-scrollbar-thumb:hover {
    background: #9d9b9b;
}

.event-ticket-scroll-container::-webkit-scrollbar {
    width: 5px;

}

.event-ticket-scroll-container::-webkit-scrollbar-track {
    background: #f1f1f1;
}

/* Handle */
.event-ticket-scroll-container::-webkit-scrollbar-thumb {
    background: #b0aeae;
    border-radius: 10px;
}

/* Handle on hover */
.event-ticket-scroll-container::-webkit-scrollbar-thumb:hover {
    background: #9d9b9b;
}
.event-ticket-scroll-container{
    /* height: 500px; */
    /* height: 100vh; */
    height: 100%; 
    max-height: 100%; 
    overflow-y: auto; /* Adds vertical scroll if content exceeds height */
    padding-right: 8px; /* Optional: Keeps some space for scrollbar visibility */
    box-sizing: border-box; /* Ensures padding and borders are included in height */
}

/* .negotiate-form{
    left: 20%;
    bottom: 50px;
} */

@media screen and (min-width: 768px) {
    .confirmation-card {
        max-height: 120vh;
    }
}

@media screen and (min-width: 992px) {
    .first-stadium-item {
        /* flex-basis: 100%; */
    }

    .second-stadium-item {
        flex-basis: 65%;
    }

    .third-stadium-item {
        /* flex-basis: 34.8%; */
    }

    /* .negotiate-form{
        left: -25%;
        bottom: 0;
    } */
}

@media screen and (min-width: 1200px) {
    .first-stadium-item {
        /* flex-basis: 10%; */
        flex-basis: unset;
        /* width: 230px; */
        width: 250px;
        padding: 14px;
    }

    .second-stadium-item {
        flex-basis: 41%;
    }

    .third-stadium-item {
        /* flex-basis: 35.8%; */
        /* flex-basis: 70.5%; */
        padding-right: 8px;
        width: calc(100% - 230px);
    }
}

@media screen and (max-width: 1024px) {
    .first-stadium-item {
        /* flex-basis: 15%; */
        padding: 10px;
        width: 330px;
    }
    .type-ticket-wrpr .type-ticket-secblock{
        width: 56px;
    }
   

    .second-stadium-item {
        flex-basis: 55%;
    }

    .third-stadium-item {
        /* flex-basis: 95%; */
        width: 900px;
    }
    .padd-right-leftsec {
        padding-right: 10px;
    }
    .instnt-dwnlodtext{
        font-size: 11px;
    }
}
@media screen and (max-width: 992px) {
    .first-stadium-item {
        width: 100%;
        padding: 15px;
    }

    .second-stadium-item {
        /* flex-basis: 95%; */
        padding: 15px;
        width: 100%;
    }
    .instnt-dwnlodtext{
        font-size: 13px;
    }
    .padd-right-leftsec {
        padding-right: 0;
    }

    .third-stadium-item {
        /* flex-basis: 95%; */
        width: 100%;
    }
    .d-flex-mob{
        display: flex;
        gap: 10px 10px;
        align-items: center;
    }
    
    .type-ticket-sec {
        width: 350px;
        gap: 10px;
        text-align: left;
    }
    .type-ticket-wrpr .type-ticket-secblock {
        width: 80px;
    }
    .d-flex-mob .form-group{
        width: 48%;

    }
    .event-ticket-scroll-container{
        padding-right: 2px;
    }
    .header-text-sec{
        border-left: 1px solid transparent;
    }
    .header-wrapper {
        padding: 15px 20px;
    }
}
@media screen and (max-width: 767px) {
    .first-stadium-item {
        flex-basis: 100%;
        width: 100%;
    }

    .second-stadium-item {
        flex-basis: 95%;
        width: 100%;
    }

    .third-stadium-item {
        flex-basis: 95%;
        width: 100%;
    }
    .d-flex-mob{
        display: unset;
        gap: 10px 40px;
    }
    .labl-flterticker {
        width: 100%;
    }
    .ticket-filter-outer {
        width: 100%;
    }
    .type-ticket-sec {
        width: 100%;
        gap: 6px;
        text-align: left;
        margin: 5px 0px 0px;
    }
    .ticket-price-wrapper{
        margin-bottom: 15px;
    }
    .d-flex-mob .form-group {
        width: 100%;
    }
    .event-ticket-scroll-container{
        padding-right: 0;
    }
}
@media (max-width: 767px) {

}