/* html{
    font-size: 13.5px;
} */
@font-face {
  font-family: 'Heebo Regular';
  src: url(../fonts/Heebo-Regular.ttf);
}
 body{
  font-family: 'Heebo Regular';
 }

.has-admin {
  font-size: 0.843rem;
}

button{
  font-size: 0.8rem !important;
}

p{
  margin-bottom: 0;
}

label {
  margin-bottom: 0.5rem;
  font-size: 0.8rem;
}

input.form-control, select.form-control, select.form-select, textarea.form-control{
  /* font-size: 0.8rem; */
  font-size: 16px;
  padding: 0.65rem 1rem;
}

.custom-file {
  position: relative;
  display: inline-block;
  width: 100%;
  height: calc(1.5em + 1.12rem + 2px);
  margin-bottom: 0;
}

.custom-file-input {
  position: relative;
  z-index: 2;
  width: 100%;
  height: calc(1.5em + 1.12rem + 2px);
  margin: 0;
  overflow: hidden;
  opacity: 0;
}
.custom-file-input:focus ~ .custom-file-label {
  border-color: #e4d6f6;
  box-shadow: 0 0 0 0.2rem transparent;
}
.custom-file-input[disabled] ~ .custom-file-label, .custom-file-input:disabled ~ .custom-file-label {
  background-color: #f0f1f5;
}
.custom-file-input:lang(en) ~ .custom-file-label::after {
  content: "Browse";
}
.custom-file-input:lang(en) ~ .disabled.custom-file-label::after {
  content: "View Certificate";
}
.custom-file-input ~ .custom-file-label[data-browse]::after {
  content: attr(data-browse);
}

.custom-file-label {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1;
  height: calc(1.5em + 1.12rem + 2px);
  padding: 0.56rem 1rem;
  overflow: hidden;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #ffffff;
  border: 1px solid #e5e9f2;
  border-radius: 0.25rem;
}
.custom-file-label::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 3;
  display: block;
  height: calc(1.5em + 1.12rem);
  padding: 0.56rem 1rem;
  line-height: 1.5;
  color: #495057;
  content: "Browse";
  background-color: #f0f1f5;
  border-left: inherit;
  border-radius: 0 0.25rem 0.25rem 0;
}

.input-group > .custom-file .custom-file-input:focus ~ .custom-file-label {
  z-index: 3;
}
.input-group > .custom-file .custom-file-input:focus {
  z-index: 4;
}
.card :where(figure:first-child) {
    overflow: hidden;
    border-start-start-radius: inherit;
    border-start-end-radius: inherit;
    border-end-start-radius: unset;
    border-end-end-radius: unset;
     margin: 0;
}
.latest-event-image{
  /* height: 14.1rem;
  width: 21.2rem; */
  /* object-fit: cover; */
  height: 8.1rem;
    width: 21.2rem;
    object-fit: cover;
}
.latest-event-image:hover{
  transform: scale(1.25);
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 1, 1);
  transition-duration: 0.5s;
}

.cookie-notice{
  box-shadow: 0px -5px 8px rgba(0, 0, 0, 0.1);
}

.page-body.modal-open{
  height: 100vh;
  overflow-y: hidden;
}

.table-column-dimension{
  min-width: 20rem;
}
