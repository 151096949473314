 :root {
   --blue: #007bff;
   --indigo: #6610f2;
   --purple: #6f42c1;
   --pink: var(--pink);
   --red: #dc3545;
   --orange: #fd7e14;
   --yellow: #ffc107;
   --green: #28a745;
   --teal: #20c997;
   --cyan: #6493fa;
   --white: #ffffff;
   --gray: #6c757d;
   --gray-dark: #343a40;
   --primary: #142862;
   --accent: #00BAFF;
   --secondary: #D1F5FF;
   --success: #0acb8e;
   --info: #04c7e0;
   --warning: #fec400;
   --danger: #fe5461;
   --light: #f0f1f5;
   --dark: #31343d;
   --info-dark: #6493fa;
   --smoke: #8a909d;
   --body-bg-color: #D8DCE3;
   --body-text-color: #868ba1;
   /* --toastify-icon-color-error: #95423E; */
   --toastify-icon-color-error: #e74c3c;
 }

 @font-face {
   font-family: 'Heebo Regular';
   src: url(../fonts/Heebo-Regular.ttf);
 }

 html,
 body {
   overflow-x: hidden;
   font-family: 'Heebo Regular';
 }

 html,
 body,
 #root {
   height: 100%;
 }

 h1,
 h2,
 h3,
 h4,
 h5,
 h6 {
   font-family: 'Heebo Regular';
 }

 .app-logo {
   width: 150px;
 }

 .hero-text {
   width: 75%;
 }

 .second-container-text {
   width: 50%;
 }

 .text-hero {
   width: 75%;
 }

 @media screen and (max-width: 768px) {
   .text-hero {
     width: 100%;
   }
 }


 .btn:disabled {
   opacity: 0.9 !important;
   border: none !important;
 }

 .list-style-type-none {
   list-style-type: none;
 }

 .disabledInstant {
   pointer-events: none;
   cursor: not-allowed;
 }

 .line-height-36 {
   line-height: 36px;
 }

 .nav-link.active {
   text-decoration: underline;
 }

 /* .test::after{
    content: "";
    flex-grow: 9999; 
    height: 0;
} */

 .scroll-content {
   white-space: nowrap;
   overflow-x: auto;
   overflow-y: hidden;
   scroll-behavior: smooth;
 }

 /* @media (min-width: 768px) and (max-width: 991px) { 
    .jumbotron-on-page{
        height: 300px;
        object-fit: cover;
    }

    .premier-league-img-md{
        height: 450px;
        object-fit: cover;
    }
} */

 @media (max-width: 576px) {
   .jumbotron-on-page-small-screen {
     height: 350px;
     object-fit: cover;
   }
 }

 @media (max-width: 767px) {
   .jumbotron-on-page {
     height: 400px;
     object-fit: cover;
   }

   .premier-league-img-md {
     height: 500px;
     object-fit: cover;
   }
 }

 @media (min-width: 768px) and (max-width: 1999px) {
   .jumbotron-on-page {
     /* height: 300px; */
     height: 250px;
     object-fit: cover;
   }

   .premier-league-img-md {
     height: 450px;
     object-fit: cover;
   }
 }

 .user-image {
   width: 40px;
   height: 40px;
   border-radius: 0.25rem;
 }

 @media (min-width: 992px) {
   .user-menu {
     min-width: 160px;
   }
 }

 .user-menu .dropdown-item {
   display: flex;
   flex-wrap: wrap;
   align-items: center;
   column-gap: 0.5rem;
 }

 .user-menu .dropdown-item .nav-text {
   margin-right: auto;
 }

 /* .user-menu .dropdown-menu {
  width: 210px;
  border-radius: 5px;
}
.user-menu .dropdown-menu li:first-child a {
  padding-top: 1.25rem;
} */
 .user-menu .dropdown-toggle {
   font-size: 0.98rem;
   padding: 0 0.5rem;
   /* line-height: 75px;
  display: flex;
  align-items: center; */
 }

 .user-menu .dropdown-toggle>span {
   color: #2f2f2f;
   padding: 0 0.5rem;
   font-size: 1rem;
   font-weight: 700;
 }

 .dropdown-menu li.dropdown-footer {
   padding: 0 0.9rem;
   padding-top: 0.7rem;
   background-color: #ffffff;
   border-top: 1px solid #e5e9f2;
   /* margin-top: 0.625rem; */
 }

 .dropdown-menu li.dropdown-footer .dropdown-footer-item {
   display: flex;
   flex-wrap: wrap;
   align-items: center;
   justify-content: space-between;
   text-decoration: none;
   /* margin-top: 0.625rem; */
 }

 .input-group.form-inp .form-control:focus {
   box-shadow: 0 0 0 .25rem rgba(20, 40, 98, .25);
   -webkit-clip-path: inset(-5px 0px -5px -5px);
   clip-path: inset(-5px 0px -5px -5px);
 }

 .input-group.form-inp .form-control:focus+.input-group-text {
   box-shadow: 0 0 0 .25rem rgba(20, 40, 98, .25);
   -webkit-clip-path: inset(-5px -5px -5px 0px);
   clip-path: inset(-5px -5px -5px 0px);
 }

 .btn-outline-gray:hover {
   background-color: #ccc;
 }


 /* .input-group.form-inp  .input-group-text + .form-control:focus{
  box-shadow:0 0 0 .25rem rgba(20,40,98,.25);
  -webkit-clip-path: inset(-5px 0px -5px -5px);
  clip-path: inset(-5px 0px -5px -5px);
}

.input-group.form-inp  .input-group-text + .form-control:focus{
  box-shadow:0 0 0 .25rem rgba(20,40,98,.25);
  -webkit-clip-path: inset(-5px -5px -5px 0px);
  clip-path: inset(-5px -5px -5px 0px);
} */


 .dropstart .dropdown-toggle::before {
   display: none;
 }

 /* .dropdown-menu li.dropdown-footer > a {
  padding: 0.94rem 1.25rem;
} */
 .Toastify__toast-container {
   width: 98%;
 }

 .Toastify__toast-container--top-center {
   left: 50%;
   transform: translateX(-50%);
 }

 .Toastify__toast-theme--light.Toastify__toast--error {
   background: #F3DFE0;
 }

 .Toastify__toast-theme--light.Toastify__toast--success {
   background: #DEF0D8;
 }

 .Toastify__toast--error .Toastify__toast-body {
   color: var(--toastify-color-progress-error);
 }

 .Toastify__toast--success .Toastify__toast-body {
   color: var(--toastify-color-progress-success);
 }

 /* .Toastify__toast-container{

} */
 .custom-list-group {
   box-shadow: 2px 5px 20px 1px rgba(0, 0, 0, 0.2);
 }

 .list-group-item.custom-list {
   border: none;
   text-wrap: nowrap;
   overflow-x: hidden;
 }

 .custom-list:hover {
   background-color: #0d6efd;
   color: #fff;
 }

 .scroll-content::-webkit-scrollbar {
   display: none;
 }

 /* Hide scrollbar for IE, Edge and Firefox */
 .scroll-content {
   -ms-overflow-style: none;
   /* IE and Edge */
   scrollbar-width: none;
   /* Firefox */
 }

 .searchbar {
   margin-top: 10px;
   max-width: 550px;
 }

 .searchbar-event:hover {
   background-color: rgba(0, 0, 0, 0.05) !important;
 }

 .text-danger.small {
   font-size: 0.8rem;
 }

 @media(min-width: 768px) {
   .admin-body .Toastify__toast-container {
     width: calc(100% - 206px);
     /* margin-left: 95px; */
   }

   .has-diff-bg .Toastify__toast-container {
     width: 65%;
     /* margin-left: 95px; */
   }

   .admin-body .Toastify__toast-container--top-center {
     left: auto;
     transform: translateX(0);
   }
 }

 @media (max-width: 575.9px) {
   .hide-profile {
     display: none;
   }

   .hide-profile-list {
     display: none;
   }
 }

 @media(min-width: 992px) {
   .admin-body .Toastify__toast-container {
     width: calc(100% - 260px);
     /* margin-left: 125px; */
   }

   .has-diff-bg .Toastify__toast-container {
     width: 50%;
     /* margin-left: 125px; */
   }
 }

 @media (min-width: 992px) {
   .user-menu .dropdown-toggle>span {
     text-overflow: ellipsis;
     max-width: 70px;
     overflow: hidden;
   }
 }

 @media (min-width: 1200px) {
   .user-menu .dropdown-toggle>span {
     max-width: 120px;
   }
 }

 @media (min-width: 1500px) {
   .user-menu .dropdown-toggle>span {
     max-width: 200px;
   }
 }

 @media (min-width: 992px) {
   .user-menu .dropdown-toggle {
     padding-right: 0.25rem;
     padding-left: 0.25rem;
   }
 }

 @media (min-width: 1200px) {
   .user-menu .dropdown-toggle {
     padding-right: 1rem;
     padding-left: 1rem;
   }
 }

 .user-menu .dropdown-toggle:after {
   border: none;
   font-family: 'Heebo Regular';
   content: "\f140";
   vertical-align: -3px;
   font-size: 1.25rem;
   transform: rotate(0);
   transition: transform 0.3s ease-in-out;
 }

 .btn-icon {
   background: transparent;
   border: none;
   outline: none;
 }

 .switch {
   position: relative;
   display: inline-block;
   width: 40px;
   height: 24px;
 }

 /* Hide default HTML checkbox */
 .switch input {
   opacity: 0;
   width: 0;
   height: 0;
 }

 /* The slider */
 .slider {
   position: absolute;
   cursor: pointer;
   top: 0;
   left: 0;
   right: 0;
   bottom: 0;
   background-color: #ccc;
   -webkit-transition: .4s;
   transition: .4s;
 }

 .slider:before {
   position: absolute;
   content: "";
   height: 16px;
   width: 16px;
   left: 4px;
   bottom: 4px;
   background-color: white;
   -webkit-transition: .4s;
   transition: .4s;
 }

 input:checked+.slider {
   background-color: #2196F3;
 }

 input:focus+.slider {
   box-shadow: 0 0 1px #2196F3;
 }

 input:checked+.slider:before {
   -webkit-transform: translateX(16px);
   -ms-transform: translateX(16px);
   transform: translateX(16px);
 }

 /* Rounded sliders */
 .slider.round {
   border-radius: 34px;
 }

 .slider.round:before {
   border-radius: 50%;
 }

 @media (min-width: 768px) {
   .user-menu .dropdown-toggle:after {
     margin-right: -7px;
   }
 }

 @media (min-width: 1200px) {
   .user-menu .dropdown-toggle:after {
     margin-right: -5px;
   }
 }

 .user-menu.show .dropdown-toggle:after {
   transform: rotate(180deg);
   transition: transform 0.3s ease-in-out;
 }

 .flex-custom {
   display: flex;
 }

 .flex-custom.col-custom {
   flex-direction: column;
   width: 30px;
   justify-content: center;
   align-items: center;
   flex-basis: 1em;
   flex-grow: 1;
   flex-shrink: 1;
 }

 /* .flex.center {
  justify-content: center;
} */

 .grow {
   flex-grow: 1;
 }

 .dots-h,
 .dots-v {
   flex-grow: 1;
   background-image: radial-gradient(3px 3px at center, rgba(20, 40, 98, 1) 3px, transparent 3px);
 }

 .dots-h {
   height: 1em;
   background-repeat: repeat-x;
   background-size: 10px 1em;
   margin: auto 0;
 }

 .dots-v {
   width: 10px;
   /* height: 100%; */
   flex-grow: 1;
   background-repeat: repeat-y;
   text-align: center;
   background-size: 10px 10px;
   /* margin: 0 auto; */
   text-align: center;
 }

 .loader-outer-bg {
   background-color: rgba(0, 0, 0, 0.4);
   position: relative;
   margin-top: 10px;
 }

 #loader {
   position: relative;
   width: 50px;
   height: 50px;
   border: 2px solid rgba(255, 255, 255, 0.2);
   border-radius: 50px;
   top: 100px;
   left: 50%;
   margin-left: -25px;
   animation-name: spinner 0.4s linear infinite;
   -webkit-animation: spinner 0.4s linear infinite;
   -moz-animation: spinner 0.4s linear infinite;
 }

 #loader:before {
   position: absolute;
   content: '';
   display: block;
   /* background-color: rgba(0,0,0,0.2); */
   width: 80px;
   height: 80px;
   border-radius: 80px;
   top: -15px;
   left: -15px;
 }

 #loader:after {
   position: absolute;
   content: '';
   width: 50px;
   height: 50px;
   border-radius: 50px;
   border-top: 2px solid white;
   border-bottom: 2px solid white;
   border-left: 2px solid white;
   border-right: 2px solid transparent;
   top: -2px;
   left: -2px;
 }

 @keyframes spinner {
   from {
     transform: rotate(0deg);
   }

   to {
     transform: rotate(360deg);
   }
 }

 @-webkit-keyframes spinner {
   from {
     transform: rotate(0deg);
   }

   to {
     transform: rotate(360deg);
   }
 }

 @-moz-keyframes spinner {
   from {
     transform: rotate(0deg);
   }

   to {
     transform: rotate(360deg);
   }
 }

 .card-loader-sec .form-select {
   font-size: 0.8rem;
   padding: 8px 1rem;
 }

 .card-loader-sec .react-datepicker-wrapper input {
   font-size: 0.8rem;
   padding: 8px 1rem;
   display: block;
   width: 100%;
   font-weight: 400;
   line-height: 1.5;
   color: var(--bs-body-color);
   appearance: none;
   background-color: var(--bs-body-bg);
   background-clip: padding-box;
   border: var(--bs-border-width) solid var(--bs-border-color);
   border-radius: var(--bs-border-radius);
   transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out
 }

 .revenueHeaderTop {
   text-align: right;
   display: flex;
   justify-content: right;
   align-items: center;
   margin-bottom: 10px;
 }

 .revenueHeaderTop .text-left {
   text-align: left;
 }

 .card-body-scroll {
   max-height: 341px;
   overflow: auto;
 }

 .card-body-scroll::-webkit-scrollbar {
   width: 5px;
   height: 5px;
 }

 /* Track */
 .card-body-scroll::-webkit-scrollbar-track {
   box-shadow: inset 0 0 5px grey;
   border-radius: 10px;
 }

 /* Handle */
 .card-body-scroll::-webkit-scrollbar-thumb {
   background: #142862;
   border-radius: 10px;
 }

 /* Handle on hover */
 .card-body-scroll::-webkit-scrollbar-thumb:hover {
   background: #142862;
 }

 .card-height {
   max-height: 452px;
 }

 .no-wrap {
   white-space: nowrap;
 }

 .text-ellipse {
   white-space: nowrap;
   width: 135px;
   overflow: hidden;
   text-overflow: ellipsis;
   /* display: inline-block; */
 }

 .text-ellipse-comments {
   white-space: nowrap;
   width: 245px;
   overflow: hidden;
   text-overflow: ellipsis;
   /* display: inline-block; */
 }

 .text-ellipse-trending {
   white-space: nowrap;
   width: 250px;
   overflow: hidden;
   text-overflow: ellipsis;
   display: inline-block;
 }

 .select-setuserType {
   border: 1px solid #212529;
   font-size: 13px;
   background: #fff;
   text-align: left;
 }

 .select-setuserType:hover {
   border: 1px solid #212529;
   border-color: #212529;
 }

 .padd-tableouter {
   padding: 20px;
 }

 .modal-popup-commission .modal-dialog {
   max-width: 650px;
 }

 .text-ellipse-single {
   white-space: nowrap;
   width: 170px;
   overflow: hidden;
   text-overflow: ellipsis;
   /* display: inline-block; */
 }

 .text-ellipse-user {
   white-space: nowrap;
   width: 125px;
   overflow: hidden;
   text-overflow: ellipsis;
 }

 .img-right {
   margin-right: 10px;
 }

 .cursor-pointer {
   cursor: pointer;
 }

 .datepick-input .react-datepicker-wrapper input {
   padding: 8px 5px;
   font-size: 12px;
 }


 .form-check-input:focus {
   outline: none;
 }


 .payable-tblouter .react-tabs__tab-list {
   display: flex;
   justify-content: space-between;
   padding-bottom: 0;
   padding-left: 0;
   padding-right: 0;
 }

 .payable-tblouter .react-tabs__tab {
   width: 25%;
   text-align: center;
   border: 1px solid unset;
   font-size: 16px;
   color: #142862;
   bottom: -2px;
   border: none;
   cursor: pointer;
   display: block;
   text-decoration: none;
   user-select: none;
   padding: 7px;
   transition: 0.3s background-color ease, 0.3s box-shadow ease;
   border: none;
   text-transform: uppercase;
 }

 .payable-tblouter .react-tabs__tab:hover {
   background-color: #f9f9f9;
   color: #142862;
 }

 .payable-tblouter .react-tabs__tab--selected {
   background-color: #fff;
   box-shadow: 0 -1px 0 #fff inset;
   cursor: default;
   border: none;
   color: #142862;
   border-bottom: 3px solid #142862;
   font-weight: 600;
 }

 .payable-tblouter .react-tabs__tab--selected::after {
   display: none
 }

 .payable-tblouter .react-tabs__tab-panel {
   padding: 10px 25px;
   background-color: transparent;
   border-radius: 6px;
 }

 .payable-tblouter .react-tabs__tab-list {
   border-bottom: 3px solid #d8dce3;
   margin: 0 0 10px;
   padding: 0;
 }

 .addTrending-labl {
   margin-left: 8px;
 }

 .tag {
   background: #3b813e;
   border-radius: 3px 0 0 3px;
   color: #fff;
   /* display: inline-block; */
   height: 23px;
   line-height: 25px;
   padding: 0 13px 0 20px;
   position: relative;
   margin: 10px 10px 10px 0;
   text-decoration: none;
   -webkit-transition: color 0.2s;
   font-size: 11px;
   text-transform: uppercase;
   font-weight: 600;
   width: 85px;
 }

 .tag::before {
   background: #fff;
   border-radius: 10px;
   box-shadow: inset 0 1px rgba(0, 0, 0, 0.25);
   content: '';
   height: 6px;
   left: 10px;
   position: absolute;
   width: 6px;
   top: 9px;
 }

 .tag::after {
   background: #f2f2f2;
   border-bottom: 12px solid transparent;
   border-right: 10px solid #3b813e;
   border-top: 11px solid transparent;
   content: '';
   position: absolute;
   left: 0;
   top: 0;
 }

 .tag:nth-child(even)::after {
   background: #fff;
   /* Background color for even tags */
 }

 .tag:hover {
   background-color: crimson;
   color: white;
 }

 .tag:hover::after {
   border-right-color: crimson;
   /* Changed from border-left-color to border-right-color */
 }

 .payable-tblouter.order-tbl .react-tabs__tab {
   width: 50%;
 }

 .table.tbl-inner-width {
   width: 95% !important;
   margin: 15px auto;
   border: 1px solid #e9e4e4;
 }

 .btn-m-right {
   margin-right: 8px;
 }

 .table.tbl-inner-width thead th {
   font-size: 13px;
   background-color: #c5cfed;
   color: #142862 !important;
   padding-top: 3px;
   padding-bottom: 3px;
 }

 .table.tbl-inner-width td {
   font-size: 12px;
 }

 .table.tbl-pending td {
   background-color: #f9f9f9;
 }

 .table.tbl-inner-width tbody td {
   background-color: #fff;
   padding: 7px;
 }

 .ticket3 {
   position: relative;
   display: flex;
   flex-direction: row;
   width: 100%;
   /* margin: 100px; */
   /* filter: drop-shadow(2px 5px 5px rgba(0, 0, 0, 0.15)); */
   /* margin: 18px 0; */
   margin: 2px 0;
   position: relative;
 }

 .ticket3__details {
   flex: 3;
   background-color: #fff;
   min-height: 50px;
   padding: 2px 10px;
   border-radius: 5px 0 0 5px;
   position: relative;
   border: 1px solid #bbb4b4;
   border-right: 0;
 }

 .ticket-textevent {
   font-size: 14px;
 }

 .bold-ticket-color {
   color: #142862;
 }

 .ticket3__details .overlay {
   position: absolute;
   top: 0;
   left: 0;
   width: 100%;
   height: 100%;
   background: rgba(0, 0, 0, 0.5);
   /* Adjust the color and opacity as needed */
   display: flex;
   align-items: center;
   justify-content: center;
   opacity: 0;
   visibility: hidden;
   transition: opacity 0.3s ease, visibility 0.3s ease;
 }

 .ticket3__details:hover .overlay {
   opacity: 1;
   visibility: visible;
 }


 .ticket3__details .ticket3__title {
   margin-top: 0;
 }

 .ticket3__details ul {
   margin: 0;
   padding: 0;
   list-style: none;
 }

 .ticket3__details ul li {
   /* margin: 3px 0; */
   margin: 0;
 }

 /* 
 .ticket3__rip {
   position: relative;
   background-color: #fff;
   background-image: linear-gradient(90deg, #fff 49%, #142862 50%);
   margin: 10px 0;
   width: 20px;
 } */

 /* .ticket3__rip::before,
 .ticket3__rip::after {
   position: absolute;
   content: "";
   width: 28px;
   height: 28px;
   border: 5px solid transparent;
   border-top-color: #fff;
   left: 50%;
   transform: translateX(-50%) rotate(135deg);
   border-radius: 50%;
 } */
 .ticket3__details::before,
 .ticket3__details::after {
   content: '';
   position: absolute;
   height: 10px;
   width: 20px;
   background-color: #ffff;
   border: 1px solid #bbb4b4;
   right: -10px;
 }

 .ticket3__details::before {
   border-radius: 150px 150px 0 0;
   border-bottom: 0;
   bottom: -1px;

 }

 .ticket3__details::after {
   top: -1px;
   border-radius: 0px 0px 150px 150px;
   border-top: 0;
 }

 /* .ticket3__rip::before {
   top: -23px;
   border-top-color: #142862;
   border-right-color: #fff;
 }

 .ticket3__rip::after {
   border-right-color: #142862;
   border-top-color: #fff;
   bottom: -23px;
   transform: translateX(-50%) rotate(-45deg);
 } */

 .ticket3__price {
   display: flex;
   flex-direction: column;
   align-items: center;
   justify-content: center;
   flex: 1;
   /* background-color: #e91e63; */
   background-color: #142862;
   min-height: 50px;
   padding: 8px 13px;
   border-radius: 0 5px 5px 0;
   color: #fff;
   padding-left: 10px;
 }

 .ticket3__price .heading {
   font-size: 14px;
   margin-bottom: 1px;
   color: #fff;
   margin-left: -5px;
 }

 .ticket3__price .price {
   font-size: 13px;
   font-weight: bold;
   margin-left: -5px;
   position: relative;
   line-height: 14px;
   text-align: right;
 }

 .section-text {
   font-size: 12px;
   /* max-width: 200px;
   white-space: nowrap; 
   overflow: hidden;
   text-overflow: ellipsis; */
 }

 .block-text-sec {
   display: flex;
   justify-content: space-between;
   font-size: 12px;
   font-weight: 600;
   text-align: center;
   align-items: center;
 }

 .ticket-card {
   background-color: #93e3e3;
   padding: 3px 7px;
   font-size: 11px;
   font-weight: 700;
   border-radius: 4px;
   margin: 2px;
 }

 .text-left {
   text-align: left;
 }

 .icon-svg {
   font-size: 20px;
   margin-right: 6px;
 }



 .btn-buy {
   background-color: #fff;
   color: #142862;
   border: none;
   padding: 2px 7px;
   border-radius: 4px;
   /* margin-top: 14px; */
   margin-top: 5px;
   width: 62px;
   opacity: 0.9;
   font-size: 11px !important;
   font-weight: 600;
   height: 19px;
 }

 .event-stadium-map-padd {
   padding: 10px 10px;
 }

 .padd-right-leftsec {
   padding-right: 10px;
   padding-left: 0;
   height: 100%;
 }

 .star-red {
   color: red;
 }

 .d-flex-web {
   display: flex;
 }

 .labl-textoffer {
   font-weight: 500;
   margin-bottom: 3px;
   margin-top: 5px;
 }

 .block-textinput .form-control {
   padding: 3px 5px;
   border: 1px solid #a3a2a2;
   border-radius: 3px;
 }

 .ticket-filter-outer {
   /* width: 240px; */
 }

 .btn-submitoffer {
   background-color: transparent;
   color: #146217;
   border: 1px solid #146217;
   padding: 4px 9px;
   border-radius: 3px;
   font-weight: 600;
   font-size: 12px !important;
   /* margin-left: 5px; */
 }

 .btn-closeoffer {
   background-color: transparent;
   color: #c31919;
   border: 1px solid #c31919;
   padding: 3px 9px;
   border-radius: 3px;
   font-weight: 600;
   margin-left: 5px;
   font-size: 12px !important;
 }

 .makeAnoffer-sec {
   color: #142862;
   text-decoration: underline;
   cursor: pointer;
   font-size: 11px;
   /* font-weight: 600; */
   /* background: #142862; */
   /* padding: 4px 8px; */
   /* border-radius: 5px; */
 }

 .totalprice-text {
   font-size: 10px;
   font-weight: 200;
 }

 .makeAnoffer-sec-botm {
   /* position: absolute; */
   /* right: 0;
   bottom: 3px;
   display: flex;
   justify-content: space-between; */
   position: relative;
 }

 /* .ofr-link-left {
   position: absolute;
   left: 0;
   bottom: -15px;
 } */

 /* .ofr-link-right {
   position: absolute;
   right: 0;
   bottom: -15px;
 } */

 .table.tbl-subinner-width thead th {
   background-color: #8da1dd;
   font-size: 12px;
 }

 .table.tbl-subinner-width tbody td {
   background-color: #eef1fd;
   padding: 7px;
 }


 .modal {
   position: fixed;
   top: 0;
   left: 0;
   width: 100%;
   height: 100%;
   display: flex;
   justify-content: center;
   align-items: center;
   background-color: rgba(0, 0, 0, 0.5);
   /* Semi-transparent background */
   z-index: 1060;
 }

 .modal__content {
   background-color: white;
   padding: 20px;
   border-radius: 8px;
   position: relative;
 }

 .modal__close {
   position: absolute;
   top: 2px;
   right: 7px;
   cursor: pointer;
 }

 .delivery-add-link {
   color: #142862;
   text-decoration: underline;
   cursor: pointer;
   white-space: nowrap;
 }

 .delivery-addresssec .list-group {
   width: 400px;
   border: 1px solid #142862;
 }

 .delivery-addresssec .lbl-delivey-width {
   width: 65px;
   font-weight: 600;
 }

 .txt-ellipsis {
   overflow: hidden;
   text-overflow: ellipsis;
   white-space: nowrap;
   display: inline-block;
   /* max-width: 150px; Adjust this value based on your layout */
   vertical-align: middle;
   width: 110px;
 }

 .txt-ellipsis-user {
   white-space: nowrap;
   width: 175px;
   overflow: hidden;
   text-overflow: ellipsis;
 }

 .text-ellipsis {
   overflow: hidden;
   text-overflow: ellipsis;
   white-space: nowrap;
   display: inline-block;
   /* max-width: 150px; Adjust this value based on your layout */
   vertical-align: middle;
   width: 180px;
 }

 .text-ellipsis-event {
   overflow: hidden;
   text-overflow: ellipsis;
   white-space: nowrap;
   display: inline-block;
   /* max-width: 150px; Adjust this value based on your layout */
   vertical-align: middle;
   width: 350px;
 }

 .text-ellipsis-list {
   overflow: hidden;
   text-overflow: ellipsis;
   white-space: nowrap;
   display: inline-block;
   /* max-width: 150px; Adjust this value based on your layout */
   vertical-align: middle;
   width: 140px;
 }

 .delivery-addresssec .list-group-item {
   border: none;
 }

 .bottom-totalprce {
   bottom: -19px;
 }

 .bottom-price {
   bottom: -50%;
 }

 .text-right-topSec {
   text-align: right;
 }

 .seats-sec-ticket {
   display: flex;
   gap: 5px;
 }

 .badge-left-ticket {
   position: absolute;
   top: -13px;
   /* transform: rotate(-91deg); */
   left: 10px;
   border-radius: 4px;
   padding: 4px 16px;
   font-size: 11px;
   z-index: 9;
   font-weight: 500;
 }

 .badge-right-ticket {
   position: absolute;
   top: 22px;
   /* background-color: #000; */
   /* transform: rotate(-91deg); */
   right: 0;
   border-radius: 4px;
   padding: 3px 12px;
   font-size: 11px;
   z-index: 9;
   font-weight: 500;
 }

 .search-input-top {
   display: flex;
   justify-content: right;
 }

 .search-input-top .form-control {
   width: 350px;
   border: 1px solid #a8aaa9;
   padding: 9px 10px;
 }

 .search-input-top .form-control::placeholder {
   font-size: 14px;

 }

 .search-input-top {
   display: flex;
   justify-content: right;
 }

 .search-input-top .form-control {
   width: 350px;
   border: 1px solid #a8aaa9;
   padding: 9px 10px;
 }

 .search-input-top .form-control::placeholder {
   font-size: 14px;

 }

 .section-block-container {
   display: flex;
   flex-direction: column;
 }

 .section-block-container span {
   display: block;
 }

 .event-sec-text {
   font-size: 14px;
 }

 .evnt-text-pl {
   padding: 0 5px;
 }

 .createdOn-text {
   color: #000;
   font-weight: 600;
 }

 .modal-editlistng .form-label {
   color: #000;
 }

 input.form-control.input-formamount {
   width: 60%;
   margin-left: 5px;
   padding: 6px;
   border-top-right-radius: 0;
   border-bottom-right-radius: 0;
 }

 .save-btnamount .btn {
   border-top-left-radius: 0;
   border-bottom-left-radius: 0;
 }

 .save-btnamount .btn {
   margin-left: -13px;
 }

 .pagination-wrapper .pagination {
   justify-content: right;
 }

 .pagination-wrapper .pagination li {
   border: 1px solid #c8ccd9;
   padding: 5px 12px;
   margin: 20px 2px 0;
   border-radius: 5px;
   background: #c8ccd9;
 }

 .pagination-wrapper .pagination li:active,
 .pagination-wrapper .pagination li:hover {
   border: 1px solid #142862;
   background-color: #142862;
   padding: 5px 12px;
   margin: 20px 2px 0;
   border-radius: 5px;
 }

 .pagination-wrapper .pagination li.active {
   background-color: #142862;
 }

 .pagination-wrapper .pagination li.active a {
   color: #fff;
 }

 .pagination-wrapper .pagination li a {
   color: #142862;
   font-size: 14px;
   font-weight: 600;
   text-decoration: unset;
 }

 .pagination-wrapper .pagination li:active a,
 .pagination-wrapper .pagination li:hover a {
   color: #fff;
 }

 .ticket-filtersec {
   /* background-color: #c0c5d7; */
   background-color: #fff;
   padding: 2px 2px;
   width: 37px;
   text-align: center;
   color: #000;
   font-size: 14px;
   font-weight: 600;
   border-radius: 5px;
   cursor: pointer;
   display: inline-block;
   margin: 3px;
   border: 1px solid #d5d8e1;
   /* box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px; */
 }

 .ticket-filtersec-select {
   background-color: #142862;
   color: #fff;
 }


 .labl-flterticker {
   font-size: 14px;
   margin-bottom: 4px;
 }

 /* .ticket-filtersec:focus,
 .ticket-filtersec:hover,
 .ticket-filtersec:active {
   background-color: #142862;
   color: #fff;
 } */

 .type-ticket-wrpr .type-ticket-secblock {
   display: inline-block;
   margin-bottom: 8px;
   background: #fff;
   padding: 5px 5px;
   width: 59px;
   border-radius: 6px;
   margin: 2px;
   /* box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px; */
   font-size: 10px;
   text-transform: uppercase;
   font-weight: 600;
   cursor: pointer;
   color: #000;
   border: 1px solid #d5d8e1;
   text-align: center;
 }

 .ui-state-default,
 .ui-widget-content .ui-state-default {
   border-radius: 100%;
 }

 /* .type-ticket-wrpr .type-ticket-secblock:hover {
   background: #142862;
   color: #fff;
 } */

 .type-ticket-wrpr .type-ticket-select {
   background: #142862;
   color: #fff;
 }

 .type-ticket-sec {
   /* display: flex; */
   /* width: 223px; */
   gap: 3px;
   margin: 5px 5px 0px;
 }

 .tbl-bg-white {
   background-color: #fff;
   padding: 1rem;
 }





 .ticket-price-wrapper .wrapper {
   /* width: 240px; */
   background: #fff;
   border-radius: 10px;
   padding: 3px 13px 19px;
   /* box-shadow: 0 12px 35px rgba(0, 0, 0, 0.1); */
 }

 .ticket-price-wrapper .price-input {
   width: 100%;
   display: flex;
   margin: 20px 0 25px;
 }

 .ticket-price-wrapper .price-input .field {
   display: flex;
   width: 45%;
   align-items: center;
   ;
 }

 .ticket-price-wrapper .field input {
   width: 100%;
   height: 100%;
   outline: none;
   font-size: 14px;
   margin-left: 7px;
   border-radius: 5px;
   text-align: center;
   border: 1px solid #999;
   -moz-appearance: textfield;
   padding: 4px 5px;
 }

 .ticket-price-wrapper input[type="number"]::-webkit-outer-spin-button,
 .ticket-price-wrapper input[type="number"]::-webkit-inner-spin-button {
   -webkit-appearance: none;
 }

 .ticket-price-wrapper .price-input .separator {
   width: 24px;
   display: flex;
   font-size: 19px;
   align-items: center;
   justify-content: center;
 }

 .ticket-price-wrapper .slider {
   height: 5px;
   position: relative;
   background: #ddd;
   border-radius: 5px;
 }

 .ticket-price-wrapper .slider .progress {
   height: 100%;
   position: absolute;
   border-radius: 5px;
   background: #142862;
 }

 .ticket-price-wrapper .range-input {
   position: relative;
 }

 .ticket-price-wrapper .range-input input {
   position: absolute;
   width: 100%;
   height: 5px;
   top: -5px;
   background: none;
   pointer-events: none;
   -webkit-appearance: none;
   -moz-appearance: none;
 }

 .ticket-price-wrapper input[type="range"]::-webkit-slider-thumb {
   height: 17px;
   width: 17px;
   border-radius: 50%;
   background: #142862;
   pointer-events: auto;
   -webkit-appearance: none;
   box-shadow: 0 0 6px rgba(0, 0, 0, 0.05);
 }

 .ticket-price-wrapper input[type="range"]::-moz-range-thumb {
   height: 17px;
   width: 17px;
   border: none;
   border-radius: 50%;
   background: #142862;
   pointer-events: auto;
   -moz-appearance: none;
   box-shadow: 0 0 6px rgba(0, 0, 0, 0.05);
 }

 select.form-select.padd-form-select {
   padding: 4px 5px;
   font-size: 12px;
 }

 .ticket-no-text {
   margin-right: 10px;
   font-weight: 600;
 }

 .color-red {
   color: red;
 }

 .color-green {
   color: green;
 }

 .color-blue {
   color: #007bff;
 }

 .link-text-paynow {
   text-align: center;
   color: grey;
   font-size: 12px;
   margin-top: 15px;
 }

 .link-paynow {
   color: #142862;
 }

 .btn.btn-i-padd {
   padding: 3px 8px 3px;
 }

 .btn-i-padd .fa-sizeset {
   font-size: 21px;
   font-weight: 700;
   color: #fff;
 }

 .fontWeight {
   font-weight: 600;
 }

 .phone-wrapper-sec {
   display: flex;
   align-items: center;
   gap: 5px;
 }

 .btn-padd-country {
   padding: 10px 8px;

 }

 .add-complaint-wrapper {
   width: 400px;
   border: 1px solid #142862;
   padding: 20px;
   border-radius: 8px;
 }

 .text-right-btn {
   text-align: right;
 }

 .lbl-text-font {
   color: #000;
   font-size: 13px;
   font-weight: 600;
 }

 .reportby {
   margin-right: 10px;
   display: flex;
   align-items: center;
   gap: 5px;
 }

 .model-sec-scroll {
   max-height: 400px;
   overflow: auto;
 }

 .model-sec-scroll::-webkit-scrollbar {
   width: 10px;
   height: 10px;
 }

 /* Track */
 .model-sec-scroll::-webkit-scrollbar-track {
   background: #f1f1f1;
 }

 /* Handle */
 .model-sec-scroll::-webkit-scrollbar-thumb {
   background: #142862;
 }

 /* Handle on hover */
 .model-sec-scroll::-webkit-scrollbar-thumb:hover {
   background: #142862;
 }

 .table.table-bordered.tbl-subinner-width {
   width: 94% !important;
   margin: 15px auto;
 }

 .form-select-w {
   /* width: 240px; */
 }

 .mob-table-section {
   display: none;
 }


 .instant-download-sec {
   margin-top: 13px;
 }

 .instant-download-sec .form-switch .form-check-input {
   border: 1px solid #a79d9d;
 }

 .tic-wrapper-width {
   width: 100%;
   margin: 0 auto;
 }

 .line-height-28 {
   line-height: 28px;
 }

 .btn.btn-hovercomunity:hover {
   color: #000;
 }

 .league-section-box {
   font-size: 18px !important;
   border: 1px solid #fff;
   /* display: flex !important;   */
   justify-content: center;
   align-items: center;
   box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
   width: 240px;
   padding: 25px 5px;
   margin-top: 15px;
 }

 .league-section-box-wrpr {
   display: flex;
   gap: 0 12px;
   align-items: center;
   justify-content: center;
   margin-top: 0px;
   margin: 0;
 }

 .ticket-img-height {
   height: 50px;
 }

 .content-text-center {
   display: flex;
   justify-content: center;
   margin: 0;
   align-items: center;
   width: 80%;
   margin: 0 auto;
 }

 .head-left-align {
   text-align: left;
   font-size: 22px;
   margin: 15px 0;
 }

 .contactus-wrapper {
   text-align: center;
   padding: 50px 30px;
 }

 .contactus-label {
   font-size: 20px;
   font-weight: 600;
   margin-bottom: 10px;
   text-transform: uppercase;
 }

 .contactus-sec {
   margin-bottom: 20px;
 }

 .contactus-head {
   font-size: 28px;
   margin-bottom: 40px;
   font-weight: 600;
   text-transform: uppercase;
   position: relative;
   color: #142862;
 }

 .contactus-head::after {
   position: absolute;
   left: 50%;
   transform: translateX(-50%);
   content: '';
   width: 70px;
   background: #142862;
   height: 3px;
   bottom: -4px;
 }

 .contact-us-div {
   display: flex;
   justify-content: space-between;
   width: 80%;
   align-items: start;
   margin: 60px auto;
   box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
   padding: 40px 20px;
   border-radius: 7px;
 }

 .contactus-text {
   font-size: 14px;
 }

 .icon-contactus-bg {
   width: 52px;
   margin: 0 auto;
   background: #e3e7eb;
   padding: 14px 13px;
   border-radius: 50%;
   margin-bottom: 20px;
   height: 52px;
 }

 .icon-contactus {
   font-size: 22px;
   color: #142862;
 }

 .icon-contactus-address {
   font-size: 28px;
 }

 .tickets-listsec .grid-container {
   display: grid;
   grid-template-columns: 1fr 1fr;
   gap: 10px;
   /* Adjust the gap as needed */
 }

 .tickets-listsec .form-check {
   display: flex;
   align-items: center;
 }

 .tickets-listsec .form-check-label {
   margin-top: 4px;
 }

 .tickets-listsec .form-check-input {
   margin-right: 10px;
 }

 .btn-no-ticket {
   background: #c1c1c1;
   border: none;
   color: #142862;
   font-weight: 600;
   padding: 10px 20px;
 }

 .restrictionsList-link {
   color: #142862;
   cursor: pointer;
   border: 1px solid #142862;
   text-align: center;
   padding: 4px 15px;
   font-weight: 600;
   border-radius: 5px;
   font-size: 14px;
 }

 .btn-close-restrictn {
   background: #fff;
   color: #142862;
   font-weight: 600;
   padding: 4px 20px;
   font-size: 13px;
   border-radius: 4px;
   border: 1px solid #142862;
 }

 .btn-close-restrictn:hover {
   background-color: #142862;
   color: #fff;
 }

 .btn-close-restrictn.modal__close {
   bottom: 6px;
   right: 34px;
   top: unset;
 }

 .modal__content.modal__content-padd {
   padding: 40px 35px;
 }

 button.btn-textFont {
   font-size: 15px !important;
   font-weight: 600 !important;
 }

 .min-height-sec {
   min-height: 500px;
 }

 @media (max-width: 1199px) {
   .content-center-width {
     width: 440px;
   }

   .nav-link {
     font-size: 14px;
   }

   button.btn-textFont {
     font-size: 14px !important;
   }
 }

 @media (max-width: 1024px) {
   .content-center-width {
     width: 250px;
   }

   .nav-link {
     font-size: 14px;
   }

   button.btn-textFont {
     font-size: 14px !important;
   }
 }

 @media (max-width: 992px) {
   .league-section-box {
     font-size: 14px;
   }

   .contact-us-div {
     display: block;
     width: 90%;
   }

   .tic-wrapper-width {
     width: 100%;
   }

   button.btn-textFont {
     font-size: 13px !important;
   }

   .btn-textFont-m {
     margin-right: 10px;
   }

   .content-center-width {
     width: 330px;
   }

   .d-flex-web {
     display: block;
   }

   .padd-right-leftsec {
     padding-right: unset;
     padding-left: unset;
     padding-bottom: 20px;
   }

   .ticket3 {
     padding: 0 10px;
   }

   .min-height-sec {
     min-height: unset;
   }


 }

 @media (max-width: 767px) {
   .payable-tblouter .react-tabs__tab {
     font-size: 13px;
   }

   .event-stadium-map-padd {
     padding: 20px 15px;
   }


   .table thead th {
     white-space: nowrap;
   }

   .payable-tblouter .react-tabs__tab-panel {
     padding: 10px 10px;
   }

   .search-input-top .form-control {
     width: 100%;
   }

   .type-ticket-sec {
     gap: 4px;
   }

   .type-ticket-wrpr .type-ticket-secblock {
     padding: 7px 14px;
     /* width: 125px; */
   }

   .modal__content {
     width: 97%;
   }

   .add-complaint-wrapper,
   .delivery-addresssec .list-group {
     width: 100%;
   }

   .btn-m-right {
     margin-bottom: 5px;
   }

   .ticket-filtersec {
     width: 60px;
     margin: 3px;
   }

   .ticket-price-wrapper .wrapper {
     width: 100%;
   }

   .form-select-w {
     width: 100%;
   }

   .ticket-price-wrapper .price-input .separator {
     width: 35px;
   }

   .ticket3 {
     margin: 15px 0;
   }

   .makeAnoffer-sec-botm {
     bottom: 1px;
   }

   .ticket3__price .price {
     font-size: 12px;
   }

   /* .block-text-sec {
  font-size: 11px;
} */
   .ticket3__details ul li {
     margin: 5px 0;
   }

   .btn-buy {
     margin-top: 20px;
   }

   /*------ mob table --------*/

   .web-show-tbl {
     display: none;
   }

   .mob-table-section {
     display: block;
     margin: 20px 0;
     /* background-color: #ececec;
     box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
     padding: 10px;
     border-radius: 8px; */
   }

   .mob-table-sec {
     display: inline-block;
     width: 45%;
     margin: 6px;
     vertical-align: top;
   }

   .mob-tbl-head {
     font-size: 13px;
     color: #000;
     font-weight: 600;
     margin-bottom: 3px;
     white-space: nowrap;
     /* font-family: 'Epilogue', sans-serif */
   }

   .mob-tbl-value {
     color: #000;
     font-size: 12px;
     font-weight: 500;
     /* font-family: 'Epilogue', sans-serif */
   }

   .mob-tbl-value.fontWeight {
     font-weight: 600;
   }

   .mob-table-sec-action {
     /* position: absolute;
    right: 0px;
    bottom: 0; */
     text-align: right;
     padding: 7px 0;
   }

   .mob-table-sec-expand {
     position: absolute;
     right: 0px;
     bottom: 0;
   }

   .mob-table-sec-action .btn-i-padd {
     border-radius: 100%;
   }

   .mob-table-sec-expand .btn-i-padd {
     border-radius: 100%;
   }

   .mob-table-sec-outer {
     position: relative;
     background-color: #ececec;
     box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
     padding: 10px;
     border-radius: 8px;
     margin-bottom: 20px;
   }

   .mob-tbl-inner-sec {
     padding: 10px;
     background-color: #fff;
     position: relative;
     border-radius: 8px;
     margin: 15px 0;
   }

   .btn-i-padd .fa-sizeset {
     font-size: 18px;
   }

   .mob-tb-subinner {
     background-color: #eef1fd;
     padding: 10px;
     border-radius: 8px;
     margin: 10px 5px;
   }

   .btn.btn-i-padd {
     padding: 3px 7px 2px;
   }

   .fa-sizeset-check {
     font-size: 13px;
   }

   .delivery-fontsize {
     font-size: 12px;
   }

   /*------ end mob table --------*/
   .league-section-box {
     padding: 8px 5px;
     margin-top: 10px;
     font-size: 12px !important;
   }

   .grid-container {
     display: grid;
     grid-template-columns: repeat(2, 1fr);
     /* 2 columns */
     gap: 1rem;
     /* Adjust the gap between items as needed */
   }

   .tickets-listsec .grid-container {
     display: unset;
   }

   .contactus-head {
     font-size: 23px;
   }

   .contact-us-div {
     width: 100%;
   }

   .btn-default {
     background-color: #007bff;
     /* Default color for both buttons */
     color: #fff;
     border: none;
   }

   .btn-no-ticket {
     background-color: #6c757d;
     /* Default color for NO button */
     color: #fff;
     border: none;
   }

   button.btn-textFont {
     font-size: 12px !important;
     padding: 5px 13px;
   }

   .btn-textFont-m {
     margin-right: 4px;
   }

   .app-logo {
     width: 135px;
   }

   .content-center-width {
     width: 100%;
   }

   .ofr-link-right {
     bottom: 3px;
   }
 }

 @media (max-width: 412px) {
   .app-logo {
     width: 110px;
   }

   .app-logo-mr {
     margin-right: 0;
   }

   .text-ellipsis-event {
     width: 240px;
   }
 }

 @media (max-width: 360px) {
   button.btn-textFont {
     font-size: 11px !important;
     padding: 5px 5px;
   }
 }

 @media (max-width: 320px) {
   .app-logo {
     width: 120px;
   }
 }



 /* Active button styles */
 .btn-active {
   background-color: #142862;
   /* Active color */
   color: #fff;
 }

 /* Optional: Hover effects */
 .btn-default:hover,
 .btn-no-ticket:hover {
   background-color: #142862;
   /* Hover color */
   color: #fff;
 }

 .padd-bottm-sec {
   padding-bottom: 8px !important;
 }

 .cardName-height-trending {
   min-height: 45px;
   /* display: block; */
   overflow: hidden;
   text-overflow: ellipsis;
   display: -webkit-box;
   -webkit-line-clamp: 2;
   -webkit-box-orient: vertical;
   line-height: 21px;
 }

 .venue-text-name {
   overflow: hidden;
   text-overflow: ellipsis;
   display: -webkit-box;
   -webkit-line-clamp: 1;
   -webkit-box-orient: vertical;
   white-space: normal;
   font-size: 13px;
 }

 .cursor-pointer {
   cursor: pointer;
 }

 .card-body-padd {
   padding: 6px 10px;
 }

 .card-date-fs {
   font-size: 14px;
 }

 .tooltipabc {
   position: absolute;
   bottom: -40px;
   left: 50%;
   transform: translateX(-50%);
   color: #142862;
   padding: 2px 5px;
   border-radius: 20px;
   font-size: 12px;
   white-space: nowrap;
   z-index: 10;
   pointer-events: none;
   width: 52px;
   text-align: center;
   /* box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px; */
   border: 1px solid #c1c1c1;
 }

 .tooltipabc:after {
   content: '';
   position: absolute;
   width: 10px;
   height: 10px;
   background-color: #fff;
   transform: rotate(45deg) translateX(-50%);
   border: 1px solid #c1c1c1;
   border-right: 0;
   border-bottom: 0;
   left: 50%;
   top: -2px;
 }


 .wrapper .ui-widget-header {
   background-color: #142862;
 }

 .ui-slider-handle {
   position: relative;
 }

 .secName-event {
   font-weight: 600;
   font-size: 14px;
   color: #000;
 }

 .ticket-subtext {
   color: #949494;
   font-weight: 200;
   font-size: 13px;
 }



 .popupalert {
   display: block;
   position: fixed;
   padding: 40px 20px;
   width: 400px;
   left: 50%;
   margin-left: -150px;
   top: 50%;
   margin-top: -100px;
   background: #FFF;
   z-index: 20;
   border-radius: 5px;
 }


 /* #popupalertSec {
   box-shadow: 0px 0px 0px 9999px rgba(0, 0, 0, .4);
 } */
 .popupOverlay {
   display: block;
   position: fixed;
   top: 0;
   left: 0;
   width: 100%;
   height: 100%;
   background: rgba(0, 0, 0, 0.4);
   /* Semi-transparent background */
   z-index: 10;
   /* Ensure it is below the popup but above other content */
   pointer-events: all;
   /* Ensure overlay captures clicks */
 }

 .btn-success-tic {
   display: flex;
   justify-content: center;
   align-items: center;
   margin: 0 auto;
 }

 .alert-success-msg {
   text-align: center;
   font-size: 15px;
   color: #142862;
   font-weight: 500;
 }

 .alert-success-msg-font {
   font-size: 20px;
 }

 .btn-alert-sec {
   text-align: center;
   margin-top: 12px;
 }

 .btn-ok-alert {
   background-color: #142862;
   color: #fff;
   border: 1px solid #142862;
   padding: 4px 16px;
   font-weight: 500;
   border-radius: 4px;
   width: 55%;
 }

 .alert-success-m-top {
   margin-top: 30px
 }


 @keyframes scaleAnimation {
   0% {
     opacity: 0;
     transform: scale(1.5);
   }

   100% {
     opacity: 1;
     transform: scale(1);
   }
 }

 @keyframes drawCircle {
   0% {
     stroke-dashoffset: 151px;
   }

   100% {
     stroke-dashoffset: 0;
   }
 }

 @keyframes drawCheck {
   0% {
     stroke-dashoffset: 36px;
   }

   100% {
     stroke-dashoffset: 0;
   }
 }

 @keyframes fadeOut {
   0% {
     opacity: 1;
   }

   100% {
     opacity: 0;
   }
 }

 @keyframes fadeIn {
   0% {
     opacity: 0;
   }

   100% {
     opacity: 1;
   }
 }

 #successAnimationCircle {
   stroke-dasharray: 151px 151px;
   stroke: #35b714;
 }

 #successAnimationCheck {
   stroke-dasharray: 36px 36px;
   stroke: #35b714;
 }

 #successAnimationResult {
   fill: #35b714;
   opacity: 0;
   stroke: #fff;
 }

 #successAnimation.animated {
   animation: 1s ease-out 0s 1 both scaleAnimation;
 }

 #successAnimation.animated #successAnimationCircle {
   animation: 1s cubic-bezier(0.77, 0, 0.175, 1) 0s 1 both drawCircle,
     0.3s linear 0.9s 1 both fadeOut;
 }

 #successAnimation.animated #successAnimationCheck {
   animation: 1s cubic-bezier(0.77, 0, 0.175, 1) 0s 1 both drawCheck,
     0.3s linear 0.9s 1 both fadeOut;
 }

 #successAnimation.animated #successAnimationResult {
   animation: 0.3s linear 0.9s both fadeIn;
 }

 input.form-control.input-availableTicketz {
   width: 4rem;
   padding: 0 .37rem 0 .87rem;
   height: 1.6rem;
   border: 1px solid #c1c1c1;
   font-size: 12px;
 }

 input.form-control.input-reservedPricePerTicket,
 input.form-control.input-price {
   width: 4.3rem;
   padding: 0 .37rem 0 .87rem;
   height: 1.6rem;
   border: 1px solid #c1c1c1;
   font-size: 12px;
 }

 .centered-div-alert {
   position: absolute;
   top: -27px;
   left: 50%;
   transform: translateX(-50%);
 }



 .overallStatus-red svg {
   width: 70px;
   height: 70px;
   transition: transform 0.5s ease-out;
 }

 #red-exclamation {
   animation: bounce 0.5s ease-out forwards;
   transform-origin: center center;
 }

 .overallStatus-red .exclamation {
   opacity: 0;
   stroke-dasharray: 100;
   stroke-dashoffset: 100;
   animation: excl 1.5s ease forwards 0.5s;
 }

 .overallStatus-red .dot {
   opacity: 0;
   stroke-width: 30;
   stroke-linecap: round;
   animation: dot-fade 0.175s ease forwards 0.7s;
 }

 @keyframes excl {
   0% {
     stroke-dashoffset: 100;
   }

   10% {
     opacity: 1;
   }

   100% {
     opacity: 1;
     stroke-dashoffset: 0;
   }
 }

 @keyframes dot-fade {
   0% {
     opacity: 0;
   }

   100% {
     opacity: 1;
   }
 }

 @keyframes bounce {
   0% {
     opacity: 0;
     transform: scale(0.1);
   }

   50% {
     transform: scale(1.0);
   }

   75% {
     transform: scale(0.6);
   }

   100% {
     opacity: 1;
     transform: scale(0.9);
   }
 }

 .red-alert-btn {
   background-color: #142862;
   border: 1px solid #142862;
 }

 .bg-grey,
 .bg-grey:hover {
   background-color: #646060;
   /* Dark grey */
 }

 .instantdownload-ticket {
   position: absolute;
   top: -13px;
   z-index: 9;
   right: 2px;
 }

 .right-0 {
   right: 0px;
 }

 .instantdownload-ticket .fa-download {
   color: #142862;
   font-size: 11px;
   cursor: pointer;
   border: 1px solid #e4e6eb;
   padding: 2px 15px;
   border-radius: 4px;
   background-color: #fff;
 }

 .txt-capitalize {
   text-transform: capitalize;
 }

 .font-event {
   font-size: 0.95rem;
 }

 .email-bg-white {
   background-color: #ffff;
   padding: 2rem;
 }

 .select-member-drop {
   margin-right: 8px;
   margin-left: 8px;
 }

 .labl-manualemail {
   font-size: 14px;
   margin-left: 8px;
   margin-right: 10px;
   margin-top: 2px;
   color: #000;
 }

 .select-memberdiv .css-b62m3t-container {
   width: 600px;
 }

 .form-select-email {
   width: 715px;
 }

 .email-mainhead {
   font-size: 20px;
   color: #000;
   margin-bottom: 15px;
 }

 .select-member-drop .css-1jqq78o-placeholder {
   color: #000;
 }

 .email-subtext {
   color: #000;
 }

 .btn-right-align {
   width: 715px;
   text-align: right;
 }

 .modal-show-address .modal__content {
   width: 500px;
 }

 .popup-headdelivery {
   font-size: 20px;
   color: #000;
 }

 .reg-phNo-input {
   width: 67%;
 }

 .reg-phcode-input {
   width: 33%;
   margin-right: 5px;
 }

 .input-group-wrap {
   flex-wrap: unset;
 }

 .loading-topsec {
   background-color: #142862;
   padding: 20px 10px;
 }

 .loading-main-text {
   text-align: center;
   color: #fff;
   font-size: 16px;
 }

 .loading-sub-text {
   font-size: 15px;
   color: #fff;
   opacity: 0.9;
   text-align: center;
 }

 .loading-btmsec {
   background-color: #fff;
   padding: 50px 10px;
   /* height: 300px; */
   display: flex;
   justify-content: center;
   align-items: center;
 }

 .loading-main-wrapper {
   width: 500px;

 }

 .list-country-ul {
   font-size: 13px;
   position: absolute;
   max-height: 150px;
   overflow-y: auto;
   font-size: 13px;
   /* width: 360px; */
   margin-top: 1px;
   top: 43px;
   z-index: 1;
 }

 .list-country-ul::-webkit-scrollbar,
 .list-group-country::-webkit-scrollbar {
   width: 6px;
 }

 /* Track */
 .list-country-ul::-webkit-scrollbar-track,
 .list-group-country::-webkit-scrollbar-track {
   background: #f1f1f1;
 }

 /* Handle */
 .list-country-ul::-webkit-scrollbar-thumb,
 .list-group-country::-webkit-scrollbar-thumb {
   background: #142862;
 }

 /* Handle on hover */
 .list-country-ul::-webkit-scrollbar-thumb:hover,
 .list-group-country::-webkit-scrollbar-thumb:hover {
   background: #142862;
 }



 .two-column-list {
   display: grid;
   grid-template-columns: repeat(2, 1fr);
   /* Two equal-width columns */
   gap: 20px 135px;
   /* Space between the items */
   list-style: none;
   padding: 0;
   padding-left: 100px;
   width: 75%;
   margin: 0 auto;
 }

 .two-column-list li {
   display: flex;
   align-items: center;
 }

 .two-column-list li img {
   margin-right: 12px;
   width: 45px;
   padding-right: 10px;
   /* Fixed width for consistent alignment */
 }

 .two-column-list li p {
   margin: 0;
 }


 .header-wrapper {
   padding: 20px 30px;
   border-bottom: 1px solid #d1d1d1;
   box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
   position: relative;
 }

 .border-right-shadow {
   border: 1px solid #d1d1d1;
   box-shadow: 4px 0 6px -1px rgba(0, 0, 0, 0.1), 2px 0 4px -1px rgba(0, 0, 0, 0.06);
   height: 100%;
 }

 .border-right-bottom-shadow {
   border: 1px solid #d1d1d1;
   box-shadow: 4px 4px 6px -1px rgba(0, 0, 0, 0.1), 2px 2px 4px -1px rgba(0, 0, 0, 0.06);
   /* max-height: 600px; */
   /* height: calc(100vh - 110px); */
   /* height: calc(100% -450px); */

 }

 .card-body-height {
   /* height: 500px; */
   /* height: calc(100vh - 90px); */
 }

 .main-headertext {
   font-size: 22px;
   color: #142862;
   font-weight: 600;
   margin-bottom: 0px;
 }

 .sub-headertext {
   font-size: 16px;
   color: #949494;
 }

 .header-text-sec {
   /* border-left: 1px solid #c4c4c4; */
   padding-left: 30px;
   position: relative;
 }

 .header-text-sec:after {
   position: absolute;
   content: '';
   height: 37px;
   width: 1px;
   top: 5px;
   left: 0;
   background-color: #c4c4c4;
 }

 .header-right-sec .search-input-header {
   border: 1px solid #dee2e6;
 }

 .btn-header-sec {
   padding: 5px 2px;
   background: #fff;
   color: #142862;
   border: 1px solid #142862;
   border-radius: 6px;
   /* font-weight: 600; */
   font-size: 13px;
   width: 100%;
   margin-right: 7px;
 }
 .btn-buyNowsec, .btn-buyNowsec:hover{
  border: 1px solid #142862;
  background-color: transparent;
  color: #142862;
  padding: 4px 8px;
 }
 .bg-btn-header {
   background-color: #142862;
   color: #fff;
 }

 .header-btn-sec {
   margin-left: 18px;
   display: flex;
 }

 .header-right-div {
   display: flex;
   justify-content: space-between;
   align-items: center;
   padding-left: 0;
 }

 .third-stadium-item {
   padding-left: 15px;
 }








 .promocode-input {
   margin-right: 10px;
   margin-left: 10px;
   padding: 5px 10px;
   border: 1px solid #c1c1c1;
   border-radius: 5px;
   width: 130px;
 }

 .promocode-input:focus {
   outline: none;
 }

 .promocode-input::placeholder {
   font-size: 12px;
 }

 .btn-outer-socialIcon {
   border-radius: 100%;
   padding: 22px 10px;
   margin: 10px 10px;
   margin-bottom: 0;
 }

 .social-icon-sec-wrapr {
   display: flex;
   justify-content: center;
   padding-top: 14px;
 }

 .user-details {
   text-align: right;
   position: relative;
   right: 5px;
 }

 .address-field {
   max-width: 150px;
   /* Adjust the width as needed */

 }

 .joined-Date {
   max-width: 140px;
 }

 .select-user-type {
   width: 100px;
   margin-right: 10px;
   margin-left: 10px;
   border: 1px solid #a8aaa9;
   padding: 6px 10px;
 }

 .select-user-type:focus,
 .select-user-type:hover {
   border: 1px solid #a8aaa9;
 }

 .instnt-dwnlodtext {
   font-size: 13px;
 }

 .ui-state-hover,
 .ui-widget-content .ui-state-hover,
 .ui-widget-header .ui-state-hover,
 .ui-state-focus,
 .ui-widget-content .ui-state-focus,
 .ui-widget-header .ui-state-focus,
 .ui-button:hover,
 .ui-button:focus {
   background-color: #fff;
   border: 1px solid #a8aaa9 !important;
 }

 .ui-widget-content .ui-state-active:hover,
 .ui-widget-content .ui-state-focus {
   background: #fff;
   border: 1px solid #a8aaa9 !important;
   outline: unset;
 }

 .ui-state-default,
 .ui-widget-content .ui-state-default {
   background: #fff !important;
   outline: unset;
   border: 1px solid #a8aaa9 !important;
 }

 .ui-widget.ui-widget-content {
   border: 1px solid transparent
   /*{borderColorDefault}*/
   ;
   background: #e3e3e3;
   border-color: unset;
 }

 .ui-state-active,
 .ui-widget-content .ui-state-active {
   border: 1px solid #a8aaa9 !important;
 }

 .header-right-sec .searchIcon-header {
   width: 28px;
 }

 .header-width-logo.col-md-4 {
   width: 25.333333%;
 }

 .header-width-textLeft.col-md-8 {
   width: 70.666667%;
 }

 .main-event {
   height: 100%;
 }

 .event-stadium-map-padd {
   height: calc(100% - 91px);
 }

 .maparea {
   height: 100% !important;
 }

 .border-right-bottom-shadow>div {
   height: 100%;
 }

 .form-inp::-webkit-scrollbar {
   width: 6px;
 }

 .form-inp::-webkit-scrollbar-track {
   background: #f1f1f1;
 }

 .form-inp::-webkit-scrollbar-thumb {
   background: #bfc2c7;
 }

 .form-inp::-webkit-scrollbar-thumb:hover {
   background: #bfc2c7;
 }

 .reg-alert-sec .margin-sec {
   margin-top: 10px;
   margin-bottom: 0;
 }

 select.form-select.form-select-listng {
   font-size: 12px;
   height: 1.6rem;
   border: 1px solid #c1c1c1;
   padding: 0.275rem 2.25rem .375rem .75rem;
 }


 select.form-select.form-select-listng-block {
   width: 70px;
 }

 /* .bg-inputSearchEvent {
   background: #f8f9fa;
   padding: 60px;
   border-radius: 10px;
   background-image: url("../images/home_jumbotron_img.jpeg");
 } */
 .bg-inputSearchEvent {
   position: relative;
   /* Set relative positioning to position the overlay */
   background: #f8f9fa;
   padding: 60px;
   border-radius: 10px;
   background-image: url("../images/home_jumbotron_img.jpeg");
   background-size: cover;
   /* Ensure the image covers the entire background */
   background-position: center;
   overflow: hidden;
   /* Hide the overlay overflow outside the border-radius */
 }

 .bg-inputSearchEvent::before {
   content: "";
   position: absolute;
   top: 0;
   left: 0;
   right: 0;
   bottom: 0;
   background-color: rgba(20, 40, 98, 0.6);
   /* Set the overlay color and opacity */
   border-radius: 10px;
   /* Match the border-radius of the parent */
   z-index: 1;
   /* Position the overlay on top of the background image */
 }

 .bg-inputSearchEvent * {
   position: relative;
   z-index: 2;
   /* Ensure the content appears above the overlay */
 }

 .input-group .border-radius {
   border-radius: 20px;
 }

 .bg-inputSearchEvent .input-group:not(.has-validation)>:not(:last-child):not(.dropdown-toggle):not(.dropdown-menu):not(.form-floating) {
   border-top-right-radius: 20px;
   border-bottom-right-radius: 20px;
 }

 .seacrh-resulteventList {
   text-align: center;
   padding: 6px 0;
   font-size: 24px;
   text-transform: uppercase;

 }

 .border-bottom-events {
   width: 100%;
   height: 3px;
   background-color: #142862;
   margin: 1px 0;
 }

 .searchbar-event.searchbar-event-hover:hover {
   background-color: #ced0d7 !important;
 }

 .eventList-headtext {
   font-size: 24px;
   font-weight: 600;
   color: #142862;
 }

 .eventlistImg {
   width: 40px;
   height: 40px;
   margin-right: 10px;
 }

 .border-btmEventList:not(:last-child) {
   border-bottom: 1px solid #d1d1d1;
 }

 .eventsearch-breadcrumb {
   margin-bottom: 20px;

 }

 .serchbtn-sec:focus,
 .serchbtn-sec:active {
   outline: none;
   background: #f8f9fa;
 }

 .serchbtn-sec {
   background: #f8f9fa;
   border-radius: 8px;
   margin-left: 20px;
   padding: 5px 12px;
   border-top-left-radius: 8px !important;
   border-bottom-left-radius: 8px !important;
 }

 .serchbtn-sec:hover {
   background: #f8f9fa;
 }

 .modal-widthSetAnAlert {
   width: 50%;
 }

 .modal-widthSetAnAlert.modal__content.modal__content-padd {
   padding: 40px 5px;
 }




 .alert-available {
  background: #dc3544;
  color: #fff;
  text-align: center;
  font-size: 11px;
  padding: 3px 5px; /* Add padding for better spacing */
  position: relative;
  height: auto; /* Ensures the height only fits the content */
  margin: auto 0; /* Aligns the box vertically in the flex container */
  white-space: nowrap; /* Prevents text wrapping */
  position: absolute;
  right: 0;
}

/* Triangular arrow on the left */
.alert-available::after {
  content: '';
  position: absolute;
  top: 50%;
  left: -10px; /* Shift the arrow to the left side */
  transform: translateY(-50%);
  width: 0;
  height: 0;
  border-top: 11px solid transparent;
  border-bottom: 11px solid transparent;
  border-right: 10px solid #dc3544; /* Change to border-right to create the arrow on the left */
}
.ticket-sec-wrpr{
  display: flex;
  gap: 2px;
  align-items: center;
}



 @media (max-width: 1024px) {
   .header-btn-sec {
     margin-left: 15px;
   }
 }








 @media (max-width: 992px) {

   .form-select-email,
   .select-memberdiv .css-b62m3t-container,
   .btn-right-align {
     width: 100%;
   }

   .list-country-ul {
     width: auto;
   }

   .content-text-center {
     width: 100%;
   }

   .two-column-list {
     width: 100%;
   }

   .app-logo {
     width: 115px;
   }

   .sub-headertext {
     font-size: 12px;
   }

   .main-headertext {
     font-size: 18px;
   }

   .header-right-div {
     display: block;
   }

   .header-btn-sec {
     margin-left: 15px;
     margin-top: 11px;
     text-align: right;
     justify-content: end;
     padding-right: 15px;
   }

   .event-ticket-scroll-container {
     height: 560px;
     max-height: 560px;
   }

   .maparea {
     height: 100% !important;
     max-height: 300px;
     min-height: 400px;
   }

   .event-stadium-map-padd {
     height: auto;
   }

   .third-stadium-item {
     padding-left: 0;
   }

   .event-ticket-scroll-container {
     height: 600px !important;
   }

   .header-text-sec:after {
     position: unset;
   }

   .seacrh-resulteventList {
     font-size: 20px;
   }

   .eventList-headtext {
     font-size: 22px;
   }
  .alert-available{
    position: relative;
  }
  .loading-main-wrapper {
    width: 60% !important;
}
.loading-topsec {
  padding: 10px 5px !important;
}

.loading-btmsec {
  padding: 20px 10px !important;

}
}

 @media (max-width: 767px) {
   .modal-show-address .modal__content {
     width: 100%;
   }

   .two-column-list {
     display: block;
   }

   .two-column-list li img {
     width: 40px;
   }

   .two-column-list li {
     margin-bottom: 20px;
   }

   .two-column-list {
     width: 100%;
     padding: 40px;
   }

   .popupalert {
     width: 96%;
     margin: 0 auto;
     left: 0;
     right: 0;

   }

   .header-right-div {
     margin-top: 10px;
   }

   .header-text-sec {
     border-left: unset;
   }

   .maparea {
     min-height: 300px;
   }

   .bg-inputSearchEvent {
     padding: 60px 10px;
   }

   .modal-widthSetAnAlert {
     width: 95%;
   }
 }

 .drop-menu {
   position: absolute;
   right: 20px;
   font-size: 14px;
   border-radius: 4px;
 }


 .primary-tab-buttons {
   display: flex;
   justify-content: space-around;
   margin-bottom: 20px;
 }

 .tab-button {
   padding: 10px 20px;
   border: none;
   border-radius: 5px;
   background-color: #f0f0f0;
   cursor: pointer;
   transition: background-color 0.3s;
 }

 .tab-button:hover {
   background-color: #e0e0e0;
 }

 .tab-button.active {
   background-color: #142862;
   /* Highlight color */
   color: white;
 }


 .register-btn {
   padding: 5px 10px;
   height: 25px;
   border: none;
   color: #ffff;
   border-radius: 5px;
   background-color: #142862;
   cursor: pointer;
   transition: background-color 0.3s;
 }

 .select-type {
   height: 25px;
 }

 .status-container {
   display: flex;
   gap: 10px;
   /* Space between the badges */
 }

 .status-badge {
   display: flex;
   justify-content: center;
   align-items: center;
   width: 75px;
   height: 30px;
   border-radius: 50%;
   color: white;
   font-size: 12px;
   font-weight: bold;
   text-align: center;
   cursor: pointer;
 }

 .complained {
   background-color: #e74c3c;
   /* Red for 'Complained' */
 }

 .on-hold {
   background-color: #f39c12;
   /* Orange for 'On Hold' */
 }

 .select-check {
   border: 1px solid light-dark(rgb(118, 118, 118), rgb(133, 133, 133));
 }

 .alert-btn {
   background-color: #142862;
   color: #fff;
   padding: 5px 12px;
   border: 1px solid #142862;
   border-radius: 6px;
   font-size: 13px;
   margin-right: 7px;
 }

 .setAnAlertPopup .form-group {
   display: flex;
   justify-content: space-between;
   align-items: center;
   gap: 20px;
 }

 .setAnAlertPopup .lbl-text-font {
   width: 70%;
 }

 .alert-btndiv {
   margin-top: 13px;
 }





