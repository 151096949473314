 :root {
   --blue: #007bff;
   --indigo: #6610f2;
   --purple: #6f42c1;
   --pink: var(--pink);
   --red: #dc3545;
   --orange: #fd7e14;
   --yellow: #ffc107;
   --green: #28a745;
   --teal: #20c997;
   --cyan: #6493fa;
   --white: #ffffff;
   --gray: #6c757d;
   --gray-dark: #343a40;
   --primary: #142862;
   --accent: #00BAFF;
   --secondary: #D1F5FF;
   --success: #0acb8e;
   --info: #04c7e0;
   --warning: #fec400;
   --danger: #fe5461;
   --light: #f0f1f5;
   --dark: #31343d;
   --info-dark: #6493fa;
   --smoke: #8a909d;
   --body-bg-color: #D8DCE3;
   --body-text-color: #868ba1;
 }
 @font-face {
  font-family: 'Heebo Regular';
  src: url(../fonts/Heebo-Regular.ttf);
}
body{
  font-family: 'Heebo Regular';
}


 .has-admin {
   font-size: .875rem;
   color: var(--body-text-color);
   background-color: var(--body-bg-color);
 }

 .has-diff-bg {
   background-color: #e9ecef;
 }

 .admin .feather {
   width: 16px;
   height: 16px;
   vertical-align: text-bottom;
 }


 /*
 * Sidebar
 */

 .admin .sidebar {
   position: fixed;
   top: 0;
   /* rtl:raw:
  right: 0;
  */
   bottom: 0;
   /* rtl:remove */
   left: 0;
   overflow-y: auto;
   z-index: 100;
   /* Behind the navbar */
   padding: 48px 0 0;
   /* Height of navbar */
   box-shadow: inset -1px 0 0 rgba(0, 0, 0, .1);
 }

 .admin-sidebar::-webkit-scrollbar {
   width: 8px;

 }

 .admin-sidebar::-webkit-scrollbar-track {
   background: #f1f1f1;
 }

 /* Handle */
 .admin-sidebar::-webkit-scrollbar-thumb {
   background: #b0aeae;
 }

 /* Handle on hover */
 .admin-sidebar::-webkit-scrollbar-thumb:hover {
   background: #9d9b9b;
 }

 @media (max-width: 767.98px) {
   .admin .sidebar {
     top: 2.5rem;
   }
 }

 .admin .sidebar-sticky {
   position: relative;
   top: 0;
   height: calc(100vh - 48px);
   padding-top: .5rem;
   overflow-x: hidden;
   overflow-y: auto;
   /* Scrollable contents if viewport is shorter than content. */
 }

 .admin .sidebar .nav-link {
   font-weight: 500;
   color: #ffffff;
   text-decoration: none;
 }

 .admin .sidebar .nav-link .feather {
   margin-right: 4px;
   color: #727272;
 }

 .admin .sidebar .nav-item {
   padding-top: 0.2rem;
   padding-bottom: 0.2rem;
 }

 .admin .sidebar .nav-item.active {
   background-color: white;
 }

 .admin .sidebar .nav-link.active {
   color: var(--primary);
 }

 .admin .sidebar .nav-link:hover .feather,
 .admin .sidebar .nav-link.active .feather {
   color: inherit;
 }

 .admin .sidebar-heading {
   font-size: .75rem;
   text-transform: uppercase;
 }

 /*
 * Navbar
 */

 .admin .navbar-brand {
   padding-top: .75rem;
   padding-bottom: .75rem;
   font-size: 1rem;
   background-color: rgba(0, 0, 0, .25);
   box-shadow: inset -1px 0 0 rgba(0, 0, 0, .25);
 }

 .admin .navbar .navbar-toggler {
   top: .25rem;
   right: 1rem;
 }

 .admin .navbar .form-control {
   padding: .75rem 1rem;
   border-width: 0;
   border-radius: 0;
 }

 .admin-list.list-group-item.active {
   background-color: rgba(0, 0, 0, .1);
   border-color: rgba(0, 0, 0, .1);
   color: rgba(0, 0, 0, .8);
 }

 .admin .form-control-dark {
   color: #fff;
   background-color: rgba(255, 255, 255, .1);
   border-color: rgba(255, 255, 255, .1);
 }

 .admin .form-control-dark:focus {
   border-color: transparent;
   box-shadow: 0 0 0 3px rgba(255, 255, 255, .25);
 }

 .dashboard-card-heading {
   font-size: 0.9rem;
 }

 .tx-spacing-1 {
   letter-spacing: 0.8px;
 }

 @media (max-width: 767px) {
   /*------ mob table --------*/

   .web-show-tbl {
     display: none;
   }

   .mob-table-section {
     display: block;
     margin: 20px 0;
     /* background-color: #ececec;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    padding: 10px;
    border-radius: 8px; */
   }

   .mob-table-sec {
     display: inline-block;
     width: 45%;
     margin: 6px;
     vertical-align: top;
   }

   .mob-tbl-head {
     font-size: 13px;
     color: #000;
     font-weight: 600;
     margin-bottom: 3px;
     white-space: nowrap;
   }

   .mob-tbl-value {
     color: #000;
     font-size: 12px;
     font-weight: 500;
   }

   .mob-tbl-value.fontWeight {
     font-weight: 600;
   }

   .mob-table-sec-action {
     /* position: absolute;
   right: 0px;
   bottom: 0; */
     text-align: right;
     padding: 7px 0;
   }

   .mob-table-sec-expand {
     position: absolute;
     right: 0px;
     bottom: 0;
   }

   .mob-table-sec-action .btn-i-padd {
     border-radius: 100%;
   }

   .mob-table-sec-expand .btn-i-padd {
     border-radius: 100%;
   }

   .mob-table-sec-outer {
     position: relative;
     background-color: #ececec !important;
     box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px !important;
     padding: 10px;
     border-radius: 8px;
     margin-bottom: 20px;
   }

   .mob-tbl-inner-sec {
     padding: 10px;
     background-color: #fff !important;
     position: relative;
     border-radius: 8px;
     margin: 15px 0;
   }

   .btn-i-padd .fa-sizeset {
     font-size: 18px;
   }

   .mob-tb-subinner {
     background-color: #eef1fd !important;
     padding: 10px;
     border-radius: 8px;
     margin: 10px 5px;
   }

   .btn.btn-i-padd {
     padding: 3px 7px 2px;
   }

   .fa-sizeset-check {
     font-size: 13px;
   }

   .delivery-fontsize {
     font-size: 12px;
   }

   /*------ end mob table --------*/
 }