/* ===== Tables ===== */
.table {
  margin-bottom: 0;
  width: 100% !important;
}
.table thead th {
  /* text-transform: uppercase; */
  color: #31343d;
  border-bottom: 0;
}
.table.table-dark thead th {
  color: #ffffff;
}

/* Produc Data Table */
.table.table-hover tbody tr:hover {
  background-color: var(--primary);
  box-shadow: 0px 10px 18px 0px rgba(55, 103, 208, 0.2);
}
.table.table-hover tbody tr:hover td {
  color: #ffffff;
}
.table.table-hover tbody tr:hover .custom-control-input:checked ~ .custom-control-label::before {
  background-color: #ffffff;
  color: var(--primary);
}
.table.table-hover tbody tr:hover .custom-checkbox .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='var(--primary)' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3e%3c/svg%3e");
}
.table.table-hover tbody tr:hover .icon-burger-mini::before,
.table.table-hover tbody tr:hover .card .dropdown .dropdown-toggle {
  color: #ffffff !important;
}

/* Table Product */
.table.table-product th, .table.table-product td {
  border-top-color: #e5e9f2 !important;
}
.table.table-product thead th {
  /* text-transform: uppercase; */
  color: var(--gray);
  border-bottom: none;
  padding-left: 10px;
  padding-right: 10px;
}
/* .table.table-product tbody td {
  padding-left: 18px;
  padding: 14px 10px;
} */
.table.table-product tbody td img {
  border-radius: 5px;
  max-width: 80px;
  max-height: 70px;
}

/* Table Thead Border */
.table-thead-border tr th {
  padding-left: 0;
  padding-right: 0;
  border-bottom: 1px solid #e5e9f2;
}
.table-thead-border tr td {
  padding-left: 0;
  padding-right: 0;
  padding-top: 0.9375rem;
  padding-bottom: 0.9375rem;
}
.table-thead-border tr td a {
  color: #31343d;
  transition: -webkit-text-decoration 0.3s ease-in-out;
  transition: text-decoration 0.3s ease-in-out;
  transition: text-decoration 0.3s ease-in-out, -webkit-text-decoration 0.3s ease-in-out;
}
.table-thead-border tr td a:hover {
  color: var(--primary);
  text-decoration: underline;
}

/* TH Width */
.th-width-250 {
  width: 250px;
}

/* ===== Data Tables ===== */
table.dataTable {
    border-collapse: separate !important
}

table.dataTable thead th, table.dataTable tbody td  {
  white-space: nowrap;
}

table.dataTable td, table.dataTable th {
    -webkit-box-sizing: content-box;
    box-sizing: content-box
}

table.dataTable td {
    padding: 0.7rem;
}

table.dataTable thead .sorting_asc,
table.dataTable thead .sorting_desc,
table.dataTable thead .sorting {
  border-bottom: 0;
  padding-right: 20px;
}
@media (min-width: 1200px) {
  table.dataTable thead .sorting_asc,
table.dataTable thead .sorting_desc,
table.dataTable thead .sorting {
    background-position-x: 90%;
  }
}

.table.dataTable.no-footer,
.dataTables_wrapper.no-footer .dataTables_scrollBody {
  border-bottom-color: #e5e9f2;
}

.dataTables_wrapper div.row:nth-child(1){
    margin-bottom: 1rem;
}

.dataTables_wrapper div.row:nth-child(2){
    align-items: center;
    margin-bottom: 1rem;
}

/* Data table Filter */
.dataTables_wrapper .dataTables_filter {
  float: none;

  text-align: left;
}
.dataTables_wrapper .dataTables_filter label {
  color: #31343d;
  font-weight: 700;
}
.dataTables_wrapper .dataTables_filter input {
  padding: 0.6rem 1rem;
  width: 24rem;
  border: 1px solid #e5e9f2;
  border-radius: 5px;
  font-size: 0.86rem;
}
.dataTables_length label{
  display: flex;
  column-gap: 0.3rem;
  align-items: center;
}

.dataTables_length label .form-select, .dataTables_length label .form-select-sm{
  width: fit-content;
   padding: .375rem 2.25rem .375rem .75rem;
}

.pagination {
  display: flex;
  padding-left: 0;
  list-style: none;
  border-radius: 0.25rem;
}

.page-link {
  position: relative;
  display: block;
  padding: 1rem 1.3125rem;
  margin-left: -1px;
  /* line-height: 1.25; */
  font-size: 0.8rem;
  color: var(--primary);
  background-color: #ffffff;
  border: 1px solid #e5e9f2;
}
.page-link:hover {
  z-index: 2;
  color: #742ed3;
  text-decoration: none;
  background-color: #f0f1f5;
  border-color: #b6bdca;
}
.page-link:focus {
  z-index: 3;
  outline: 0;
  box-shadow: 0 0 0 0.2rem transparent;
}

.page-item:first-child .page-link {
  margin-left: 0;
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}
.page-item:last-child .page-link {
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
}
.page-item.active .page-link {
  z-index: 3;
  color: #ffffff;
  background-color: var(--primary);
  border-color: var(--primary);
}
.page-item.disabled .page-link {
  color: #6c757d;
  pointer-events: none;
  cursor: auto;
  background-color: #ffffff;
  border-color: #b6bdca;
}

.pagination-lg .page-link {
  padding: 0.75rem 1.5rem;
  font-size: 1.171875rem;
  line-height: 1.5;
}
.pagination-lg .page-item:first-child .page-link {
  border-top-left-radius: 0.3rem;
  border-bottom-left-radius: 0.3rem;
}
.pagination-lg .page-item:last-child .page-link {
  border-top-right-radius: 0.3rem;
  border-bottom-right-radius: 0.3rem;
}

.pagination-sm .page-link {
  padding: 0.25rem 0.5rem;
  font-size: 0.75rem;
  line-height: 1.5;
}
.pagination-sm .page-item:first-child .page-link {
  border-top-left-radius: 0.2rem;
  border-bottom-left-radius: 0.2rem;
}
.pagination-sm .page-item:last-child .page-link {
  border-top-right-radius: 0.2rem;
  border-bottom-right-radius: 0.2rem;
}

@media (max-width: 450px) {
  .dataTables_wrapper .dataTables_filter input {
  padding: 0.7rem 0.9rem;
  width: 18rem;
} 
}

@media (min-width: 992px) {
  .dataTables_wrapper .dataTables_filter {
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }  
}

/* DataTable Info */

.dataTables_info{
    margin-top: 0.7rem;
}

/* Datatable Pagination */
.dataTables_paginate.paging_simple_numbers {
  margin-top: 30px;
  display: flex;
  justify-content: flex-end;
}
.dataTables_paginate.paging_simple_numbers .paginate_button {
  border: 1px solid #e5e9f2 !important;
  background: none !important;
  background-color: #ffffff;
  padding: 0;
}
.dataTables_paginate.paging_simple_numbers .paginate_button .page-link {
  padding: 0.6rem 1rem;
  width: fit-content;
}


/* empty */
table.dataTable td.dataTables_empty, table.dataTable th.dataTables_empty {
    text-align: center
}

/* Sorting */
table.dataTable thead > tr > td.sorting, table.dataTable thead > tr > td.sorting_asc, table.dataTable thead > tr > td.sorting_desc, table.dataTable thead > tr > th.sorting, table.dataTable thead > tr > th.sorting_asc, table.dataTable thead > tr > th.sorting_desc {
    padding-right: 30px
}

table.dataTable thead > tr > td:active, table.dataTable thead > tr > th:active {
    outline: 0
}

table.dataTable thead .sorting, table.dataTable thead .sorting_asc, table.dataTable thead .sorting_asc_disabled, table.dataTable thead .sorting_desc, table.dataTable thead .sorting_desc_disabled {
    cursor: pointer;
    position: relative;
    color: var(--secondaryColor)
}

table.dataTable thead .sorting:after, table.dataTable thead .sorting:before, table.dataTable thead .sorting_asc:after, table.dataTable thead .sorting_asc:before, table.dataTable thead .sorting_asc_disabled:after, table.dataTable thead .sorting_asc_disabled:before, table.dataTable thead .sorting_desc:after, table.dataTable thead .sorting_desc:before, table.dataTable thead .sorting_desc_disabled:after, table.dataTable thead .sorting_desc_disabled:before {
    position: absolute;
    bottom: .9em;
    display: block;
    opacity: .5
}

table.dataTable thead .sorting:before, table.dataTable thead .sorting_asc:before, table.dataTable thead .sorting_asc_disabled:before, table.dataTable thead .sorting_desc:before, table.dataTable thead .sorting_desc_disabled:before {
    right: 1em;
    content: "\2191";
    font-size: 15px
}

table.dataTable thead .sorting:after, table.dataTable thead .sorting_asc:after, table.dataTable thead .sorting_asc_disabled:after, table.dataTable thead .sorting_desc:after, table.dataTable thead .sorting_desc_disabled:after {
    right: .5em;
    content: "\2193";
    font-size: 15px
}

table.dataTable thead .sorting_asc:before, table.dataTable thead .sorting_desc:after {
    opacity: 1
}

table.dataTable thead .sorting_asc_disabled:before, table.dataTable thead .sorting_desc_disabled:after {
    opacity: 0
}

table.dataTable > tbody > tr.child ul.dtr-details {
    width: 100%;
}
.dataTables_processing.card{
  /* background-color: rgba(0, 0, 0, 0.05); */
  border: none;
  border-radius: 0;
  text-align: center;
  font-size: larger;
  padding: 0.5rem;
  color: rgba(0, 0, 0, 0.6);
  
}
/* #loader {
    display: none;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    z-index: 1000;
}

#loader img {
    width: 50px;
    height: 50px;
} */
/* @media (min-width: 768px) {
  .dataTables_paginate.paging_simple_numbers .paginate_button {
    padding: 10px 20px;
  }
}
.dataTables_paginate.paging_simple_numbers .paginate_button:hover {
  color: #31343d !important;
}
.dataTables_paginate.paging_simple_numbers .paginate_button.current {
  background: none !important;
  background-color: var(--primary) !important;
  border-color: var(--primary) !important;
  color: #ffffff !important;
}
.dataTables_paginate.paging_simple_numbers .paginate_button.current:hover {
  color: #ffffff !important;
}
.dataTables_paginate.paging_simple_numbers .paginate_button.previous.disabled,
.dataTables_paginate.paging_simple_numbers .paginate_button.next.disabled {
  color: #8a909d !important;
  border-color: #e5e9f2 !important;
}
.dataTables_paginate.paging_simple_numbers .paginate_button.previous,
.dataTables_paginate.paging_simple_numbers .paginate_button.next {
  color: var(--primary) !important;
  border-color: var(--primary) !important;
  display: none;
}
@media (min-width: 576px) {
  .dataTables_paginate.paging_simple_numbers .paginate_button.previous,
.dataTables_paginate.paging_simple_numbers .paginate_button.next {
    display: inline-block;
  }
} */
